import React, { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  HeaderNavigation,
  HeaderPanel,
  SearchFormDefault,
  SearchFormBg,
  HeaderNavigationMobile,
  ModalLogin,
  ModalSignup,
  ModalRecovery,
} from '@features';
import { Tags, CookiesPolicyPopup, TechWorksPopup } from '@widgets';
import { BROWSE_URL, ADMIN_URL, ADMIN_PROJECT_CREATE_URL } from '@app/routes';
import {
  HEADER_NAVIGATION_LINKS,
  HEADER_NAVIGATION_LOGO,
  HEADER_NAVIGATION_LOGO_CUT,
} from './header_full.consts';
import {
  logoutTC,
  getActiveSearch,
  getProductsTC,
  getCartTC,
  getCustomer,
  setSearchAC,
  getModalLoginShow,
  getModalSignupShow,
  getModalRecoveryShow,
} from '@entities';
import { clearAllNode, searchValueToArray } from '@shared';
import { SearchFormContext } from '@shared/hooks';

const HeaderFull = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchValue = useSelector(getActiveSearch);
  const isModalLoginShow = useSelector(getModalLoginShow);
  const isModalSignupShow = useSelector(getModalSignupShow);
  const isModalRecoveryShow = useSelector(getModalRecoveryShow);
  const { pathname, search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useSelector(getCustomer);

  const IS_BROWSER_PAGE = pathname === BROWSE_URL.pathname;
  const IS_ADMIN_URL = pathname.includes(ADMIN_URL.pathname);
  const IS_TECHWORKS_SHOW = localStorage.getItem('accept_tech_works') !== 'true';
  const IS_COOKIE_SHOW = 
    localStorage.getItem('accept_cookie') !== 'true' && 
    sessionStorage.getItem('decline_cookie') !== 'true';

  const FULL_PATH = `${pathname}${search}`;

  if (!IS_ADMIN_URL && !user.id) {
    localStorage.setItem('fullpath', FULL_PATH);
  }

  /*if (FULL_PATH.includes(ADMIN_PROJECT_CREATE_URL)) {
    localStorage.setItem('fullpath', FULL_PATH);
  }*/

  if (FULL_PATH.includes(ADMIN_PROJECT_CREATE_URL.pathname)) {
    localStorage.removeItem('fullpath');
  }

  /*
   * GET initial data
   */
  useEffect(() => {
    if (!user.id) {
      return;
    }

    dispatch(getCartTC(user.basketId));
    // eslint-disable-next-line
  }, [dispatch, user.id]);

  const onChangeSearch = (event) => {
    const value = event?.target?.value || '';

    dispatch(setSearchAC(value));
  };

  const onSelectTag = (value) => {
    onSubmitSearch({
      preventDefault: () => {},
      target: { search: { value } },
    });
  };

  const onSubmitSearch = (event) => {
    event.preventDefault();

    const search_value = event?.target?.search?.value || '';
    let searchByString;

    searchParams.delete('asset_id');
    searchParams.delete('searchBy');

    searchParams.set('page', '1');
    searchParams.set('limit', '80');

    const orderBy = searchParams.get('orderBy');
    if (!orderBy) {
      searchParams.set('orderBy', 'popular');
    };

    if (search_value) {
      var valueWithautSpaces = search_value.replace(/\s\s+/g, ' ');

      const multiSearchArray = searchValueToArray(valueWithautSpaces);

      multiSearchArray.forEach((searchItem) => {
        setSearchParams(
          (prevParams) => {
            prevParams.append('searchBy', searchItem);
          },
          { replace: true }
        );
      });

      searchByString = multiSearchArray.join(',');
    } else {
      searchByString = '';
    }

    setSearchParams(searchParams);
    dispatch(setSearchAC(searchByString));

    clearAllNode('div[data-info-last]');

    const path = {
      pathname: BROWSE_URL.pathname,
      search: window.location.search,
    };

    navigate(path);
    dispatch(getProductsTC(path.search));
    setTimeout(() => {
      document.querySelector('#root').scrollIntoView();
    }, 500);
  };

  const logout = async () => {
    dispatch(logoutTC());
  };

  const DefaultHeader = (
    <div className="d-flex jc-sb header__wrapper">
      <SearchFormContext.Provider
        value={{
          onSubmitSearch,
          onChangeSearch,
          searchValue,
        }}
      >
        <HeaderNavigationMobile headerLinks={HEADER_NAVIGATION_LOGO_CUT} />
      </SearchFormContext.Provider>

      <HeaderNavigation headerLinks={HEADER_NAVIGATION_LINKS} />
      {!IS_BROWSER_PAGE && (
        <SearchFormDefault
          onSubmit={onSubmitSearch}
          onChange={onChangeSearch}
          placeholder="Search"
          value={searchValue}
        />
      )}
      <HeaderPanel logout={logout} />
    </div>
  );

  const BrowseScrollHeader = (
    <div className="header__wrapper-scroll">
      <div className="d-flex mb-24">
        <HeaderNavigation headerLinks={HEADER_NAVIGATION_LOGO} className="mr-16" />
        <SearchFormBg
          onSubmit={onSubmitSearch}
          onChange={onChangeSearch}
          placeholder="Search"
          value={searchValue}
        />
      </div>
      <Tags onSelectTag={onSelectTag} />
    </div>
  );

  return (
    <>
      {IS_TECHWORKS_SHOW && <TechWorksPopup />}
      {IS_COOKIE_SHOW && <CookiesPolicyPopup />}
      <header className="header">
        <div className="full__container">
          {BrowseScrollHeader}
          {DefaultHeader}
        </div>
      </header>
      {isModalLoginShow && <ModalLogin /> }
      {isModalSignupShow && <ModalSignup /> }
      {isModalRecoveryShow && <ModalRecovery /> }
    </>
  );
};

export default HeaderFull;
