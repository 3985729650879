export const SET_LOADING              = 'SET_LOADING';
export const SET_PRODUCTS             = 'SET_PRODUCTS';
export const DELETE_PRODUCT           = 'DELETE_PRODUCT';
export const DELETE_CHECKED_PRODUCTS  = 'DELETE_CHECKED_PRODUCTS';

export const SET_MOUNTHLY_AMOUNT      = 'SET_MOUNTHLY_AMOUNT';
export const SET_ENTIRE_AMOUNT        = 'SET_ENTIRE_AMOUNT';
export const SET_PAGINATION_AMOUNT    = 'SET_PAGINATION_AMOUNT';

export const SET_MS_CONNECTED         = 'SET_MS_CONNECTED';
