import React, { useEffect, useState, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Recapcha } from '@shared';
import './profile-password.scss';

import { useForm } from '@shared';
import { Text, Button, Input } from '@ui-kit';
import { 
  updatePasswordTC, 
  getServerError, 
  setServerErrorsAC, 
  getCustomer 
} from '@entities';
import onResetFormValid from './password-validation.hook';
import onCaptchaValid from '../../forms/security-form/captcha-validation.hook';
import { LoadingAdmin } from '@shared/ui';
import EyeOnIcon from '@ui-kit/icons/eye-gray400.svg';
import EyeOffIcon from '@ui-kit/icons/eye-off-gray400.svg';

const ProfilePassword = () => {
  const dispatch = useDispatch();
  const recaptchaRef = createRef();
  const serverError = useSelector(getServerError);
  const user = useSelector(getCustomer);

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isCurrentShow, setCurrentShow] = useState(false);
  const [isPasswordShow, setPasswordShow] = useState(false);
  const [isConfirmShow, setConfirmShow] = useState(false);

  const SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;
  const eyeCurrentIcon = isCurrentShow ? EyeOnIcon : EyeOffIcon;
  const inputCurrentType = isCurrentShow ? 'text' : 'password';
  const eyeIcon = isPasswordShow ? EyeOnIcon : EyeOffIcon;
  const inputType = isPasswordShow ? 'text' : 'password';
  const eyeConfirmIcon = isConfirmShow ? EyeOnIcon : EyeOffIcon;
  const inputConfirmType = isConfirmShow ? 'text' : 'password';
  const password = {
    newPassword: '',
    confirmPassword: '',
  };

  const { formData, onChangeInput, onSubmitForm, setFormData } = useForm(
    password,
    handlerBeforeSubmitingForm
  );

  const classConfirm = ['mt-50'];
  if (errors.confirmPassword) {
    classConfirm.push('form-item__error');
  };

  useEffect(() => {
    return () => dispatch(setServerErrorsAC([]));
  }, [dispatch]);

  async function handlerBeforeSubmitingForm() {
    recaptchaRef.current.execute();
    const formErrors = onResetFormValid(formData);

    if (Object.keys(formErrors).length) {
      return setErrors({...errors, ...formErrors});
    }

    setErrors({});
    dispatch(setServerErrorsAC([]));
  }

  const onSecuritySuccess = async (token) => {
    formData.captchToken = token;
    formData.email = user.email;

    recaptchaRef.current.reset();

    if (Object.keys(errors).length) {
      return;
    }

    setIsLoading(true);
    await dispatch(updatePasswordTC(formData));

    setIsLoading(false);
  };

  const onSecurityError = () => {
    const captchaError = onCaptchaValid({ captchToken: null });

    setErrors({ ...errors, ...captchaError });
  };

  const onRecaptchaChange = (token) => {
    if (!token) {
      onSecurityError();
    }

    return  onSecuritySuccess(token);
  } 

  const onIconClick = (options) => {
    switch (options) {
      case 'current':
        if (isCurrentShow) {
          setCurrentShow(false);
          break;
        }
        setCurrentShow(true);
        break;

      case 'password':
        if (isPasswordShow) {
          setPasswordShow(false);
          break;
        }
        setPasswordShow(true);
        break;

      case 'confirm':
        if (isConfirmShow) {
          setConfirmShow(false);
          break;
        }
        setConfirmShow(true);
        break;

      default:
        break;
    }
  };

  const resetSettings = () => {
    setFormData(password)
    setErrors({});
    setCurrentShow(false);
    setPasswordShow(false);
    setConfirmShow(false);
  };

  return (
    <div className="profile__password">
        {isLoading && <LoadingAdmin />}
        <div className="profile__password-header">
            <Text as="h2" className="title">
            Password
            </Text>
            <Text as="h5" className="sub-title">
            Please enter your current password to change your password.
            </Text>
        </div>
        <div className="profile__password-container">
            <form onSubmit={onSubmitForm} className="profile__password-form">
              <input 
                type="text" 
                hidden
                readOnly
                name="email"
                autoComplete="email username"
                value={user.email}
              />
              <Input
                  placeholder="Current password"
                  name="currentPassword"
                  type={inputCurrentType}
                  autoComplete="current-password"
                  required
                  label="Current password"
                  value={formData?.currentPassword || ""}
                  onChange={onChangeInput}
                  className={errors?.currentPassword && 'form-item__error'}
                  iconRight={eyeCurrentIcon}
                  onIconClick={() => onIconClick("current")}
              />
              {errors.currentPassword && (
                  <Text as="span" className="error">
                  {errors.currentPassword}
                  </Text>
              )}

              <Input
                  placeholder="Create a new password"
                  name="newPassword"
                  type={inputType}
                  autoComplete="new-password"
                  required
                  label="New password"
                  value={formData?.newPassword || ""}
                  onChange={onChangeInput}
                  className={`mt-50${errors?.newPassword ? ' form-item__error': ''}`}
                  iconRight={eyeIcon}
                  onIconClick={() => onIconClick("password")}
              />
              {errors.newPassword && (
                  <Text as="span" className="error">
                  {errors.newPassword}
                  </Text>
              )}
              <Input
                  placeholder="Confirm password"
                  name="confirmPassword"
                  type={inputConfirmType}
                  autoComplete="new-password"
                  required
                  label="Confirm password"
                  value={formData?.confirmPassword || ""}
                  onChange={onChangeInput}
                  className={classConfirm.join(' ')}
                  iconRight={eyeConfirmIcon}
                  onIconClick={() => onIconClick("confirm")}
              />
              {errors.confirmPassword && (
                <Text as="span" className="error">
                {errors.confirmPassword}
                </Text>
              )}

              <div className="profile__buttons-block">
                <Button 
                  type="button"
                  size="md" 
                  theme="secondary-gray"
                  onClick={resetSettings}
                >
                  Cancel
                </Button>
                <Button size="md">
                  Update password
                </Button>
              </div>

              <Recapcha
                recaptchaRef={recaptchaRef}
                className="g-recaptcha"
                sitekey={SITE_KEY}
                onChange={onRecaptchaChange}
                onErrored={onSecurityError}
                size= "invisible"
                //badge="inline"
              />

              {errors.captcha && (
                <Text as="span" className="error">
                {errors.captcha}
                </Text>
              )}
              {/* server errors block */}
              {!!serverError.length && (
                <div className="server__error">
                  {serverError.map((error) => (
                    <Text as="span" key={error.param} className="error">
                      {error.msg}
                    </Text>
                  ))}
                </div>
              )}
            </form>
        </div>
    </div>
  );
};

export default ProfilePassword;
