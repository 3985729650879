import React, { useContext } from 'react';
import './adminInfoWrapper.scss';
import AdminProjectInfo from './adminProjectInfo';
//import AdminInfoCost from './adminInfoCost';
import { useSelector } from 'react-redux';
import { ProjectsContext } from '@shared/hooks';
import { getCurrentProject } from '@entities';

const AdminInfoWrapper = (/*{isActivePanel = 'chat'}*/) => {
  const currentProject = useSelector(getCurrentProject);
  const projectsContext = useContext(ProjectsContext);
  const { activePanel } = projectsContext;

  if (activePanel === 'chat') {
    return;
  }

  const { dir, sketchImage, renderImage } = currentProject;

  const renderView = () => {
    if (activePanel === 'info') {
      return renderInfoView();
    }

    //TODO
    /*  if (isActivePanel === "costs") {
      return <AdminInfoCost />
    } */
  };

  const renderInfoView = () => {
    let concatPath;
    if (sketchImage || renderImage) {
      concatPath = `${dir}/${renderImage ? renderImage : sketchImage}`;
    }

    return <AdminProjectInfo pathTo={concatPath} currentProject={currentProject} />;
  };

  return <div className="admin-info__wrapper">{renderView()}</div>;
};

export default AdminInfoWrapper;
