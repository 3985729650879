import React from 'react';
import './avatar-group.scss';
import { geShortCut } from '@shared';
import { Avatar, Text, Icon } from '@ui-kit';
import PlusIcon from '@ui-kit/icons/plus-gray400.svg';

const AvatarGroup = ({
  users = [],
  size = "sm",
  maxCount = 4,
  addUser = false,
  moreUser = true
}) => {
    if (!users.length) {
      return;
    }

    const classes = ["avatar-group", size];

    // to feature
    const addAvatar = () => {
      console.log('-> add user');
    };

    if (users.length > 1) {
      const renderUsers = users.length > maxCount ? 
        users.slice(0, maxCount) : users;

      return (
        <div className={classes.join(' ')}>
          {renderUsers.map(({ ava, id, ...user }, index) => (
            <Avatar
              size={size}
              key={`user-${index}`}
              src={`${ava}`}
              text={geShortCut(user.firstName, user.lastName)}
            />
          ))}
          {(moreUser && users.length - maxCount > 0) && (
            <Avatar
              size={size}
              lable={false}
              text={`+${users.length - maxCount}`}
            />
          )}
          {addUser && (
            <div className="avatar-add" onClick={addAvatar()}>
              <Icon src={PlusIcon} />
            </div>
          )}
        </div>
      );
    }

    const { id, ava, firstName, lastName } = users[0];

    return (
      <div className={classes.join(' ')} key={`user-${id}`}>
        <Avatar
          size={size}
          key={`user-one`}
          src={`${ava}`}
          text={geShortCut(firstName, lastName)}
        />
        <div className="user-name">
          <Text as="h5">{firstName || ''} {lastName || ''}</Text>
        </div>
      </div>
    );
};

export default AvatarGroup;
