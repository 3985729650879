import React, { useEffect, useState, useRef } from 'react';
import './cart-summary.scss';

import { Message } from '@features';
import { useSelector, useDispatch } from 'react-redux';
import { Text, Icon, Button, Tooltip } from '@ui-kit';
import {
  Bot,
  setServerErrorsAC,
  payInvoiceTC,
  getPaymentData,
} from '@entities';
import Lock from '@ui-kit/icons/lock-gray400.svg';
import Arrow from '@ui-kit/icons/chevron-accent700.svg';
import Question from '@ui-kit/icons/help-circle-gray400.svg';
import { useOnScreen } from '@shared';

const CartSummary = (props) => {
  const { 
    tax = 0,
    text = '',
    cart = [],
    payLabel = '',
    step = 'cart',
    currencySymbol = '$',
    isConfirmData = true,
    payProcess = ()=>{},
    setIsSummaryVisible = () => {},
    totalPrice = 0,
  } = props;
  const totalResume = useRef();
  const selectedResume = useRef();
  const dispatch = useDispatch();
  const paymentData = useSelector(getPaymentData);
  const [showSaves, setShowSaves] = useState(false);
  const PRICE_ANALOGS = 35;
  const isCart = step === 'cart';
  const isVisibleTotal = useOnScreen(totalResume);
  const isVisibleSelect = useOnScreen(selectedResume);
  const isVisibleSummary = isVisibleTotal || isVisibleSelect;
  const symbol = currencySymbol || '$';

  useEffect(() => {
    if (!totalPrice) return;
    // eslint-disable-next-line
  },[])
  
  useEffect(() => {
    return () => {
      dispatch(setServerErrorsAC([]));
    };
  }, [dispatch]);

  useEffect(() => {
    setIsSummaryVisible(isVisibleSummary);
    // eslint-disable-next-line
  }, [isVisibleSummary])

  const totalAmount = cart.reduce(
    (accumulator) => accumulator + PRICE_ANALOGS,
    0
  );
  const cartPrice = Math.ceil((totalPrice - tax) * 100) / 100;

  const onClickPay = () => {
    if(isCart) {
      return payProcess();
    };

    dispatch(payInvoiceTC(paymentData));
  };

  const benefits = (totalAmount - totalPrice).toFixed(2);

  const botMessages = new Bot().getCartMessages(benefits);

  const vatTooltipContent = (
    <>
      <Text as="h6" className="tooltipe__title">
        The amount of tax depends on the selected <br />
        country when placing an order.
      </Text>
    </>
  );

  const totalTooltipContent = (
    <>
      <Text as="h6" className="tooltipe__title">
        Regular price
      </Text>

      <Text as="h6" className="tooltipe__subtitle">
        Comparing to the other big microstock platforms <br />
        platforms. The average price of the enhaced <br />
        license: 35$.Prices are as of 09/26/2023.
      </Text>
    </>
  );

  return (
    <div className="cart__summary">
      <div>
        {botMessages.map((message, index) => (
          <Message
            isUser={true}
            ownerId={null}
            message={message}
            key={`message-${index}`}
          />
        ))}
      </div>

      <div className="cart-summary__item">
        <Text as="h3" className="cart-summary__title">
          Your shopping cart
        </Text>
        <Text as="h5" className="cart-summary__title-desc">
          {cart.length} items
        </Text>
      </div>

      <div className="cart-summary__item p-t-b divider">
        <Text as="h5" className="cart-summary__subtitle">
          Illustrations{' '}
          <Text as="span" className="gray-text">
            ({cart.length})
          </Text>
        </Text>

        <Text as="h5" className="cart-summary__subtitle-desc">
          {`${cartPrice} ${symbol}`}
        </Text>
      </div>

      {!isCart && (
        <div className="cart-summary__item p-t-b divider">
          <Text as="h5" className="cart-summary__subtitle">
            VAT/GST/Sale taxes
          </Text>

          <Text as="h5" className="cart-summary__subtitle-desc">
            {tax ? `${tax} ${symbol}` : text}
          </Text>
        </div>
      )}

      <div ref={totalResume} className="cart-summary__item p-t-b cart-summary__item-total">
        <div className="d-flex ai-center cart-summary__total_descr">
          <Text as="h5" className="cart-summary__total">
            Total
          </Text>
          {isCart && (
            <Text as="h5" className="vat_notincluded">
              (VAT not included)
            </Text>
          )}

          <Tooltip
            content={vatTooltipContent}
            position="top"
            direction="revers"
            ancorPosition="anchor-right-bottom"
          >
            <Icon
              src={Question}
              className="question__icon"
              alt="question__icon"
            />
          </Tooltip>
        </div>
        <Text as="h5" className="cart-summary__total-desc">
          {`${totalPrice.toFixed(2)}`}{` ${symbol}`}
        </Text>
      </div>

      <div
        className="cart-summary__item cursor-hover cart-summary-saves"
        onClick={() => setShowSaves(!showSaves)}
      >
        <div className="d-flex ai-center">
          <Text as="h5" className="cart-summary__subtitle">
            You save
          </Text>
          <Icon
            src={Arrow}
            className={`arrow__icon ${showSaves ? 'down' : ''}`}
            alt="arrow__icon"
          />
        </div>

        <Text as="h5" className="cart-benefit__subtitle-desc bold">
          {benefits}{` ${symbol}`}
        </Text>
      </div>

      {showSaves && (
        <>
          <div className="cart-summary__item">
            <div className="d-flex ai-center">
              <Text as="h5" className="cart-summary__subtitle">
                Regular price ({cart.length} items)
              </Text>
              <Tooltip
                content={totalTooltipContent}
                position="top"
                direction="revers"
                ancorPosition="anchor-right-bottom"
              >
                <Icon
                  src={Question}
                  className="question__icon"
                  alt="question__icon"
                />
              </Tooltip>
            </div>

            <Text as="h5" className="cart-benefit__subtitle-desc bold">
              {totalAmount}{` ${symbol}`}
            </Text>
          </div>
        </>
      )}

      <Button
        size="lg"
        className="pay__link"
        onClick={onClickPay}
        isDisabled={!isConfirmData}
      >
        {payLabel}
      </Button>

      <div ref={selectedResume} className="d-flex ai-center jc-center cart-security">
        <Icon src={Lock} className="cart-summary-lock" />
        <Text as="h5">Secure payment with the Stripe</Text>
      </div>
    </div>
  );
};

export default CartSummary;
