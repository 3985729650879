import React from 'react';
import './adminInfoMenu.scss';
import { Button } from '@ui-kit';

export const AdminInfoMenu = ({ setActive = ()=>{}, activeInfo = '' }) => {
  return (
    <div className="admin__info">
      <div className={`admin__info-menu d-flex ${activeInfo}`}>
        <Button 
          theme="tertiary-gray" 
          size="md"
          onClick={() => setActive('info')}
        >
          Info
        </Button>
        {/* <Button
          theme="tertiary-gray" 
          size="md"
          onClick={() => setActive('costs')}
        >
          Costs
        </Button> */}
      </div>
    </div>
  );
};

export default AdminInfoMenu;
