import React, {useCallback} from 'react';
import { useDispatch } from 'react-redux';
import './profile-purchases.scss';

import productService from '@entities/products/api';
import { Text } from '@ui-kit';
import { Snackbar, setAlertAC } from '@entities';
import PurchasedItem from './purchasedItem';

const PurchasedProducts = ({products, isUser}) => {
    const dispatch = useDispatch();

    const downloadArchive = useCallback((data, name) => {
        try {
            const blob = new Blob([new Uint8Array(data.data.Body.data)], {
                type: 'application/octet-stream',
              });

            const ourLink = URL.createObjectURL(blob);
            let link = document.createElement('a');

            link.href = ourLink;
            link.download = `${name}.zip`;
            link.click();
        } catch (error) {
            const snackbar = new Snackbar({
                status: 500,
                message: (
                    <div className="">
                    <h5 className="snack__header">
                        Can't download the archive.
                    </h5>
                    </div>
                ),
            });
            console.log('[ERROR]', error);
            dispatch(setAlertAC(snackbar));
        }
        // eslint-disable-next-line
    },[]);

    const makeRequest = async (param) => {
        const data = await productService.getArchive(param);
    
        return downloadArchive(data, param.name);
    };

    return (
        <>
            <div className="admin__table-header">
                <Text as="h6" className="product-name">
                    Name
                </Text>
                <Text as="h6" className="product-illustrator">
                    Illustrator
                </Text>
                <Text as="h6" className="product-date">
                    Date
                </Text>
                <Text as="h6" className="product-download">
                    Download an illustration
                </Text>
            </div>
            <div className="admin__table-body">
                {products?.map((item, index) => (
                    <PurchasedItem
                    key={`profile-purchase-${index}`}
                    item={item}
                    makeRequest={makeRequest}
                    />
                ))}
            </div>
        </>
    );
};

export default PurchasedProducts;