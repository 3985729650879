import React, { useEffect, useState } from 'react';

import { Icon, Dropdown, Text } from '@ui-kit';
import Search from '@ui-kit/icons/search-gray500.svg';
import { countriesWithIcons } from './countriesIFasade';

const CountriesDropDown = ({
  current = '',
  errors = {},
  showArrow = true,
  isDisabled = false,
  onChooseCountry,
  iconRight,
}) => {
  const [flag, setFlag] = useState();

  useEffect(() => {
    const currentCountry = countriesWithIcons.find(
      (item) => item.value === current
    );

    if (!currentCountry) {
      return setFlag(Search);
    }

    setFlag(currentCountry?.icon || Search);
  }, [current]);

  const className = [];

  if (errors?.country) {
    className.push('form-item__error');
  }

  const renderCountryPreview = (event, index) => {
    const name = event?.name;
    const code = event?.code;
    const icon = event?.icon || null;
    let classes = ['pr', 'input-search__item'];

    if (!code || !name) return;

    if (code === current) {
      classes.push('active');
    }

    const ico = icon ? (
      <Icon src={icon} alt="flag" />
    ) : (
      <Icon text={code} alt="flag" />
    );

    return (
      <div
        className={classes.join(' ')}
        key={`country-${index}`}
        onClick={() => onChooseCountry(event)}
      >
        {ico}
        <Text as='span'>{name}</Text>
      </div>
    );
  };

  return (
    <div className="profile__input">
      <Dropdown
        search={true}
        value={current}
        iconLeft={flag}
        id="input-search"
        showArrow={showArrow}
        className={className}
        iconRight={iconRight}
        isDisabled={isDisabled}
        data={countriesWithIcons}
        placeholder="Select country"
        onChooseCountry={onChooseCountry}
        renderOption={renderCountryPreview}
        autoComplete="false"
      />
      {errors?.country && (
        <Text as="span" className="error">
          {errors?.country}
        </Text>
      )}
    </div>
  );
};

export default CountriesDropDown;
