import {
    VACANCY_ILLUSTRATOR_URL,
    //VACANCY_THEME_MAKER_URL,
    //VACANCY_SOFTWARE_TESTER_URL,
    //VACANCY_PROJECT_MANAGER_URL,
} from '@app/routes';

export const VACANCIES = [
    {
        id: "vacancies.illustrator.",
        title: "Vector illustrator",
        description: "We are looking for a cool vector illustrator who will work and develop with us.",
        salary: "1300",
        time: "Full time work",
        url: VACANCY_ILLUSTRATOR_URL
    },
    /*{
        id: "vacancies.programmer.ue.",
        title: "Programmer Unreal Engine",
        description: "We are looking for a programmer who can join us and create a wonderful game together in the visual novel genre.",
        salary: "1500",
        time: "Full time work",
        url: VACANCY_PROGRAMMER_UE_URL
    },*/
    /*{
        id: "vacancies.theme.maker.",
        title: "Drawing themes maker",
        description: "We are looking for a person to join our team who will select drawing topics for our illustrators.",
        salary: "800+",
        time: "Full time work",
        url: VACANCY_THEME_MAKER_URL
    },*/
    /*{
        id: "vacancies.content.manager.",
        title: "Content manager",
        description: "We are looking for a cool and responsible content manager.",
        salary: "1300",
        time: "Full time work",
        url: VACANCY_CONTENT_MNGR_URL
    },*/
    /*{
        id: "vacancies.software.tester.",
        title: "Software tester",
        description: "We are looking for a cool and responsible software tester.",
        salary: "300",
        time: "Underemployment",
        url: VACANCY_SOFTWARE_TESTER_URL
    },*/
    /*{
        id: "vacancies.project.manager.",
        title: "Project manager",
        description: "We are looking for a project manager for our new game.",
        salary: "1500",
        time: "Full time work",
        url: VACANCY_PROJECT_MANAGER_URL
    },*/
];
