import React, {useEffect, useState, useRef} from "react";
import { useSearchParams } from 'react-router-dom';
import "./filters-checked.scss";

import { Button, CheckBox, Icon } from "@ui-kit";
import ChevronIcon from '@ui-kit/icons/chevron-sm-gray500.svg';

const FiltersChecked = ({children = ()=>{}, filters = [], onChangeFilter, className = ''}) =>{
    const menuRef = useRef();
    const listRef = useRef();
    const [selectedFilters, setSelectedFilters] = useState({});
    const [arrowCondition, setArrowCondition] = useState({
        transform: 'rotate(0deg)',
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const classes = ["filters-checked__container", className];

    if (!filters.length) {
        classes.push("empty-filters")
    }

    useEffect(() => {
        if (!filters.length) return;

        document.addEventListener('click', handleClickOutside);

        const newSelected = {}

        filters.map((filter) => {
            const searchValue = searchParams.get(filter.order);
            if (searchValue === filter.value) {
                newSelected[filter.name] = {order: filter.order, value: filter.value}
            };
            return filter;
        });

        if(!Object.keys(newSelected).length) {
            filters.map((filter) => 
                newSelected[filter.name] = {order: filter.order, value: filter.value}
            );
        }
        
        setSelectedFilters(newSelected);

        return () => {
            document.removeEventListener('click', handleClickOutside)
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const array = Object.keys(selectedFilters);
        if(!array.length || array.length === filters.length) return;

        array.map((filter) => 
            searchParams.set(selectedFilters[filter].order, selectedFilters[filter].value)
        );

        setSearchParams(searchParams);
        // eslint-disable-next-line
    }, [selectedFilters]);

    const handleClickOutside = (event) => {
        console.log('click');
        if (!menuRef.current || !menuRef.current.contains(event.target)) {
            const isShow = listRef?.current?.classList?.contains('show');

            if (isShow) {
                listRef.current.classList.remove('show');
                setArrowCondition({
                    transform: 'rotate(0deg)',
                });
            };
        };
    };

    const onChange = (event, filter) => {
        event.stopPropagation();
        const newSelectedFilters = { ...selectedFilters };

        if (newSelectedFilters[filter.name]) {
            delete newSelectedFilters[filter.name];
            setSelectedFilters(newSelectedFilters);
            searchParams.delete(filter.order);
        } else {
            newSelectedFilters[filter.name] = {order: filter.order, value: filter.value};
            setSelectedFilters(newSelectedFilters);
            searchParams.set(filter.order, filter.value)
        }

        if (filters.length === Object.keys(newSelectedFilters).length) {
            const isButton = false;
            return onReset(isButton);
        }

        setSearchParams(searchParams);

        setTimeout(() => onChangeFilter(), 500);
    };

    const onReset = (isButton = true) => {
        filters.map((filter) => 
            searchParams.delete(filter.order)
        );

        setSearchParams(searchParams);
        const newSelected = {}
        filters.map((filter) => 
            newSelected[filter.name] = {order: filter.order, value: filter.value}
        );
        setSelectedFilters(newSelected);

        setTimeout(() => {
            onChangeFilter();
            if (isButton) onButtonClick();
        }, 500);
    }
    
    const onButtonClick = () => {
        listRef.current.classList.toggle('show');
        const isShow = listRef.current.classList.contains('show');

        if (isShow) {
            return setArrowCondition({
                transform: 'rotate(180deg)',
            });
        }

        setArrowCondition({
            transform: 'rotate(0deg)',
        });
    };

    const renderItem = (filter, index) => {
        const filterClasses = ['filter__item']

        if (!!selectedFilters[filter.name]) {
            filterClasses.push('active');
          } 
    
        return (
            <CheckBox
                key={`checkbox-${index}`}
                size="sm"
                label={filter.name}
                className="checkbox__item"
                checked={!!selectedFilters[filter.name]}
                onChange={(event) => onChange(event, filter)}
            />
        )
    };

    return (
        <div className={classes.join(" ")} ref={menuRef}>
            <div className="filter-checked__button d-flex" onClick={onButtonClick}>
                {children}
                <Icon 
                    src={ChevronIcon} 
                    styles={arrowCondition} 
                    className="filter-checked__chevron" 
                />
            </div>
            <div className="filter-checked__list-wrapper" ref={listRef}>
                <ul className="filter-checked__list">
                    {filters.map((filter, index) => (
                        renderItem(filter, index)
                    ))}

                    <Button
                        theme="link-gray"
                        size="md"
                        onClick={onReset}
                    >
                        Reset
                    </Button>
                </ul>
            </div>
        </div>
    );
}

export default FiltersChecked;