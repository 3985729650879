import React from 'react';
import { useDispatch } from 'react-redux';

import './modal-login.scss';
import { LoginForm } from '@widgets';
import { setModalLoginShowAC } from '@entities';
import { Icon } from '@ui-kit';
import CloseIcon from '@ui-kit/icons/x-gray700.svg';

const ModalLogin = () => {
  const dispatch = useDispatch();

  const onBackgroundClick = (e) => {
    if (e.target.id === 'login-background') {
      dispatch(setModalLoginShowAC(false));
    }
  };

  const closeModal = () => {
    dispatch(setModalLoginShowAC(false));
  };

  return (
    <div 
      id="login-background" 
      className="login-modal"
      onMouseDown={onBackgroundClick}
    >
      <div className="login-modal__wrapper">
          <LoginForm />
          <div className="modal__close" onClick={closeModal}>
            <Icon src={CloseIcon} alt="close" />
          </div>
      </div>
    </div>
  );
};

export default ModalLogin;
