import React, {useEffect, useState, useRef} from "react";
import { useSearchParams } from 'react-router-dom';
import "./filters.scss";

import { Button, Text } from "@ui-kit";
import Filter from '@ui-kit/icons/filter-gray700.svg';

const Filters = ({filters = [], filterOrder = '', onChangeFilter}) =>{
    const listRef = useRef();
    const [name, setName] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    const classes = ["filters__container"];

    if (!filters.length) {
        classes.push("empty-filters")
    }

    useEffect(()=> {
        const activeValue = filters.find((item) => item.name === name);
        if (!activeValue) {
          setName('');
          return;
        }
     
        setName(activeValue.name);
        // eslint-disable-next-line
    }, [name]);

    useEffect(() => {
        const searchValue = searchParams.get(filterOrder);
        const activeValue = filters.find((item) => item.value === searchValue);
        
        if (!activeValue) {
            return setName('');
        };

        setName(activeValue.name);
        // eslint-disable-next-line
    }, [filters]);

    const onChange = (event) => {
        const newValue = event.target.id;
        const newName = event.target.innerText;

        if(newName === name) return; 
        setName(newName);

        searchParams.set(filterOrder, newValue);
        setSearchParams(searchParams);
        onChangeFilter();
    };
    
    const onButtonClick = () => {
        listRef.current.classList.toggle("show");
    };

    const onBlurClick = () => {
        setTimeout(() => listRef.current.classList.remove("show"), 300);
    }

    const renderItem = (item, index) => {
        const itemClasses = ['filter__item']

        if (item.name === name) {
            itemClasses.push('active');
          } 
    
        return (
            <Text 
                key={`item-${index}`}
                as="li" 
                className={itemClasses.join(' ')}
                id={item.value}
                onClick={onChange} 
            >
                {item.name}
            </Text>
        )
    };

    return (
       <div className={classes.join(" ")}>
            <Button
                theme="secondary-gray"
                size="md"
                iconLeft={Filter}
                onClick={onButtonClick}
                onBlur={onBlurClick}
            >
            </Button>
            <div className="filter__list-wrapper" ref={listRef}>
                <ul className="filter__list">
                    {filters.map((item, index) => (
                        renderItem(item, index)
                    ))}
                </ul>
            </div>
       </div>
    );
}

export default Filters;