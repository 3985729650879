import React, { useState, useContext } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import './header-active-panel.scss';

import { Avatar, Link, Badge, DropdownMenu, Text, Button } from '@ui-kit';
import { UserContext } from '@shared/hooks';
import { geShortCut } from '@shared';
import { 
  ADMIN_USER_PROJECTS_URL,
  ADMIN_PROJECTS_URL,
  CART_URL, 
  CAREER_URL 
} from '@app/routes';
import { AvatarLabel, HeaderActiveLang } from '@features';
import { 
  getLocale, 
  setLocaleAC, 
  setModalLoginShowAC, 
  setModalSignupShowAC,
} from '@entities';
import { ROLES } from '@entities/user';
import CartGray from '@ui-kit/icons/cart-gray500.svg';
import Bell from '@ui-kit/icons/bell-gray500.svg';
import LogoutSmal from '@ui-kit/icons/logout-gray700.svg';

import { MENU_LIST, MENU_LIST_ADMIN } from './header-active.consts';

const HeaderPanel = ({ logout = () => {} }) => {
  const dispatch = useDispatch();
  const user = useContext(UserContext);
  const locale = useSelector(getLocale)
  const { pathname } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const { isUserAuth = null, isUserOnline = false, ava = null } = user;

  const [showMenu, setShowMenu] = useState(false);
  const IS_CAREER_PAGE = pathname === CAREER_URL.pathname;
  const langTitle = locale === 'ru' ? 'Ru' : 'En';
  const isUser = user.role === ROLES.USER;
  const userMenu = isUser ? MENU_LIST : MENU_LIST_ADMIN;
  const cartCount = user.cart.length || 0;
  const bellURL = isUser ? ADMIN_USER_PROJECTS_URL.pathname : ADMIN_PROJECTS_URL.pathname;

  const selectLanguage = (value) => {
    dispatch(setLocaleAC(value));
    searchParams.set('lang', value);
    setSearchParams(searchParams);
  }

  const LOGOUT_COMPONENT = {
    component: (
      <Text as="span" onClick={logout} className="menu_logout">
        Log out
      </Text>
    ),
    icon: LogoutSmal,
  };

  let menuListWithAvatarLabel = [
    {
      component: (
        <AvatarLabel
          author={user}
          showEmail={true}
          className="menu-hover__disabled"
        />
      ),
      isDevider: true,
    },
    ...userMenu,
    LOGOUT_COMPONENT,
  ];

  const renderBadge = (counter) => {
    if (!counter) {
      return;
    }

    return (
      <Badge
        text={counter}
        size="md"
        theme="success"
        className="header__wrapper-badge"
      />
    );
  };

  const onVisibleChange = () => {
    setShowMenu(false);
  };

  const viewModalLogin = () => {
    dispatch(setModalSignupShowAC(false));
    dispatch(setModalLoginShowAC(true));
  }

  const viewModalSignup = () => {
    dispatch(setModalLoginShowAC(false));
    dispatch(setModalSignupShowAC(true));
  }

  const getActions = () => {
    if (isUserAuth) {
      return (
        <div className="d-flex ai-center">
          
          {IS_CAREER_PAGE && (
            <HeaderActiveLang 
              title={langTitle}
              selectLanguage={selectLanguage}
              className="mr-30"
            />
          )}

          <div className="d-flex header__link-notification">
            <Link
              to={bellURL}
              icon={Bell}
              size="sm"
              theme="text"
              className={``}
              disabled={!user.updatedProjects?.length}
            />
            {renderBadge(user.updatedProjects?.length)}
          </div>
          <div className="d-flex header__link-cart">
            <Link
              to={CART_URL}
              icon={CartGray}
              size="sm"
              theme="text"
              className={` d-flex ai-center}`}
            />
            {renderBadge(cartCount)}
          </div>

          <DropdownMenu
            menu_list={menuListWithAvatarLabel}
            isVisible={showMenu}
            onVisibleChange={onVisibleChange}
            classes="menu-default"
          >
            <Avatar
              isUserOnline={isUserOnline}
              src={ava}
              status={true}
              onClick={() => setShowMenu(!showMenu)}
              className="drop-down__ava"
              text={geShortCut(user.firstName, user.lastName)}
            />
          </DropdownMenu>
        </div>
      );
    }

    return (
      <div className="d-flex ai-center ml-18 notauth">
        {IS_CAREER_PAGE && (
            <HeaderActiveLang 
              title={langTitle}
              selectLanguage={selectLanguage}
            />
          )}
        <Button onClick={viewModalLogin} theme="tertiary-gray">
          Log in
        </Button>
        <Button onClick={viewModalSignup} className="ml-12" size="lg">
          Sign up
        </Button>
      </div>
    );
  };

  return <div className="header__panel d-flex ai-center">{getActions()}</div>;
};

export default HeaderPanel;
