import { createSelector } from 'reselect';

export const getUsersData = (state) => state.usersData;

export const getUsers = createSelector(
  getUsersData,
  (usersData) => usersData.users
);

export const getUsersIsLoading = createSelector(
  getUsersData,
  (usersData) => usersData.isLoading
);

export const getUsersPaginationAmount = createSelector(
  getUsersData,
  (usersData) => usersData.paginationAmount
);

export const getUsersLimit = createSelector(
  getUsersData,
  (usersData) => usersData.usersLimit
);
