import React, { useEffect, useRef, useState } from 'react';
import './hoverProductCard.scss';
import { useSelector } from 'react-redux';
import { Text, Tooltip, Button, Icon } from '@ui-kit';
import { AvatarLabel } from '@features';
import { getLimitCart } from '@entities';
import { LICENSES_URL } from '@app/routes';
import Cart from '@ui-kit/icons/cart-gray700.svg';
import Check from '@ui-kit/icons/check-white.svg';


const HoverProductCard = ({ 
  author = {},
  addToCart = () => {},
  inCart = false,
}) => {
  const [ widthCard , setWidthCard ] = useState('');
  const [ license, setLicense ] = useState('Extended commercial license');
  const href = useRef();
  const isCartLimit = useSelector(getLimitCart);

  useEffect(() => {
    if(widthCard) {
      setResizer();
    }
    // eslint-disable-next-line
  }, [widthCard]);

  const setResizer = () => {
    const MAX_RESIZE_WIDTH = 320;
    const CRITICAL_WIDTH = 215;

    const current = href.current.parentNode;
    const parent = current.parentNode;
    const { width } = parent.getBoundingClientRect();
    setWidthCard(width);

    if (width > MAX_RESIZE_WIDTH) {
      current.classList.remove('resizer', 'cut-text', 'cut-license');
      setLicense('Extended commercial license');
    }

    if (width > CRITICAL_WIDTH && width < MAX_RESIZE_WIDTH) {
      current.classList.add('resizer');
      current.classList.remove('cut-text');
      setLicense('Extended commercial');
      if (inCart) {
        current.classList.add('cut-license');
      } else {
        current.classList.remove('cut-license');
      }
    }

    if (width < CRITICAL_WIDTH) {
      current.classList.add('resizer', 'cut-text');
    }
  };

  const onLinkClick = (e) => {
    e.stopPropagation();
    window.open(`${LICENSES_URL.pathname}#rights`, '_blank');
    e.stopPropagation();
  }

  const onOver = () => {
    href.current.style.display = 'flex';
    setResizer();

  }

  const onLeave = () => {
    href.current.style.display = 'none';
  }

  const renderCartIcon = () => {
    if (inCart) {
      return (
      <div className='d-flex ai-center'>
        <Icon src={Check}/>
        <Text as="h6" className="cart__ico-text">
          Item in Cart
        </Text>
      </div>
      );
    }

    if (isCartLimit) {
      return (
        <Tooltip
        position="top"
        direction="revers"
        ancorPosition="anchor-right-bottom"
        content={
          <>
            <Text as="h6" className="hoverbackground__tooltip">
              Limit is exceeded
            </Text>
            <Text as="h6" className="hoverbackground__learn-more">
              You can add 50 items maximum in the cart.
            </Text>
          </>
        }
      >
        <Button
          size="lg"
          iconLeft={Cart}
          onClick={addToCart}
          className="cart__button cart__icon"
        >
          <Text as="h6" className="cart__ico-text">
            Add to Cart
          </Text>
        </Button>
      </Tooltip>
        );
    }

    return (
      <Tooltip
        position="top"
        direction="revers"
        ancorPosition="anchor-right-bottom"
        content={
          <>
            <Text as="h6" className="hoverbackground__tooltip">
              Add to Cart for $2
            </Text>
            <Text as="h6" className="hoverbackground__learn-more">
              All illustrations in our gallery for $2
            </Text>
          </>
        }
      >
        <Button
          size="lg"
          iconLeft={Cart}
          onClick={addToCart}
          className="cart__button cart__icon"
        >
          <Text as="h6" className="cart__ico-text">
            Add to Cart
          </Text>
        </Button>
      </Tooltip>
    );
  };

  return (
    <div 
      onMouseOver={onOver}
      onMouseLeave={onLeave}
      className="hoverbackground__container" 
    >
      {author && (
        <div
          ref={href}
          key={`author-${author.id}`}
          className="d-flex jc-sb ai-end parent__info"
        >
          <div className="hoverbackground__info">
            <AvatarLabel author={author} className="mb-8" />
            <Tooltip
              content={
                <>
                  <Text as="h6" className="hoverbackground__tooltip">
                    License for any purpose
                  </Text>
                  <Text as="h6" className="hoverbackground__learn-more">
                    Learn more about license options
                  </Text>
                </>
              }
            >
              <Button
                theme="link-gray"
                size="sm"
                className="license"
                onClick={onLinkClick}
              >
                {license}
              </Button>
            </Tooltip>
          </div>
          {renderCartIcon()}
        </div>
      )}
    </div>
  );
};

export default HoverProductCard;
