import React, {useState, useRef} from "react";
import './modal-image.scss';

import { Icon } from '@ui-kit';
import CloseIcon from '@ui-kit/icons/x-white.svg'

const ModalImage = ({className = '', src}) => {
    const refImage = useRef();
    const [ isOpen, setIsOpen ] = useState(false);
    const [ isScale, setIsScale ] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const classes = ['modal-image__background', ...className.split(' ')]
    const imgClasses = ['modal-image__image'];

    if (isOpen) {
        classes.push('open');
    }

    if (isScale) {
        classes.push('scale');
        imgClasses.push('scale');
    }

    const openModal = () => {
        setIsOpen(true)      
    };

    const closeModal = () => {
        setIsOpen(false)
        setIsScale(false);
    }

    const scaleImage = (event) => {
        setIsScale(!isScale);
        setPosition({x: event.clientX, y: event.clientY});
    }

    const moveScroll = (event) => {
        if (!isScale) {
            return
        }

        const factor = 2;
        const diffX = (event.clientX - position.x) * factor;
        const diffY = (event.clientY - position.y) * factor;
        
        refImage.current.scrollLeft += diffX;
        refImage.current.scrollTop += diffY;

        setPosition({x: event.clientX, y: event.clientY});
    }

    return (
        <div className="modal-image">
            <div onClick={openModal} className="modal__children">
                <Icon src={src} className="img-sm" />
            </div>
            <div className={classes.join(' ')} >
                <div className={imgClasses.join(' ')} ref={refImage}>
                    <img 
                        src={src}
                        onClick={scaleImage}
                        onMouseMoveCapture={moveScroll}
                        alt=""
                    />
                </div>
                <div className="modal__top-panel">
                    <div className="modal__close">
                        <Icon src={CloseIcon} onClick={closeModal} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalImage;