import React from 'react';
import './price_line.scss';
import { Text, Icon, Tooltip, Modal } from '@ui-kit';
import Qestion from '@ui-kit/icons/help-circle-gray400.svg';

const licensesTooltipe = (
  <>
    <Text as="h6" className="hoverbackground__tooltip-title">
      Price varies depending on taxes
    </Text>
    <Text as="h6" className="hoverbackground__tooltip">
      The price is not final and can <br />
      include taxes (VAT) depending on <br />
      the region.
    </Text>
  </>
);

const licensesModal = (
  <>
    <Text as="h6" className="modal__title">
        Price varies depending on taxes
      </Text>
    <Text as="h6" className="modal__subtitle">
      The price is not final and can
      include taxes (VAT) depending on
      the region.
    </Text>
  </>
);

const PriceLine = ({ price, currency }) => {
  return (
    <div className="price_line mb-20 d-flex ai-center">
      <Text as="h5" className="info__header mr-8">
        Price:
      </Text>
      <Text as="h5" className="info__content mr-8">
        {currency}
        {price}
      </Text>
      <Tooltip
        content={licensesTooltipe}
        position="left"
        ancorPosition="anchor-right-middle"
      >
        <Icon src={Qestion} className="question__icon" alt="question__icon" />
      </Tooltip>

      <Modal
        content={licensesModal}
      >
        <Icon src={Qestion} className="question__icon" alt="question__icon" />
      </Modal>
    </div>
  );
};

export default PriceLine;
