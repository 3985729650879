import React, { useEffect } from 'react';
import './table-users.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Text } from '@ui-kit';
import UserItem from './UserItem';
import { getUserTC, getCurrentUser, updateLocalUserTC } from '@entities';

const TableUsers = ({users = []}) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const [searchParams, setSearchParams] = useSearchParams();

  const onChoose = (id) => {
    dispatch(getUserTC(id));
    searchParams.set('user_asset_id', id);
    setSearchParams(searchParams);
  };

  const listener = (event) => {
    const data = event?.data;

    if (!data) return;

    const toJson = JSON.parse(data);
    const customEvent = toJson.event;

    if (customEvent === `model.user.afterSave`) {
      const savedUser = toJson?.data;

      dispatch(updateLocalUserTC(Object.assign({}, currentUser, savedUser)));
    }
  };

  useEffect(() => {
    window.socket?.socket.addEventListener("message", listener);

    return () => {
      window.socket?.socket.removeEventListener("message", listener);
    };
    // eslint-disable-next-line
  }, [window.socket]);

  return (
    <div className="admin__table">
      <div className="admin__table-header">
        <Text as="h6" className="user-name">
          Name
        </Text>
        <Text as="h6" className="user-surname">
          Surname
        </Text>
        <Text as="h6" className="user-role">
          Role
        </Text>
        <Text as="h6" className="user-email">
          Email
        </Text>
        <Text as="h6" className="user-create">
          Create
        </Text>
      </div>
      <div className="admin__table-body">
        {users.map((item, index) => (
          <UserItem
            key={`admin-user-${index}`}
            user={item}
            onClick={onChoose}
          />
        ))}
      </div>
    </div>
  );
};

export default TableUsers;
