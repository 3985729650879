import React, {useState} from 'react';
import './item-actions.scss';
import { DropdownMenu } from '@ui-kit';

const ItemActions = ({ actionsList = [], title = ':', onSelect = ()=>{}, className = '' }) => {
    const [showMenu, setShowMenu] = useState(false);
    const classes = ["item-actions", ...className.split(' ')];
    const menuClasses = ["menu-actions", ...className.split(' ')];

    const onClickInternal = (value) => {
        setShowMenu(value);
    };

    const onVisibleChange = (event) => {
        const value = event.target?.parentNode?.id;
        if (value) {
            onSelect(value);
        };
        onClickInternal(false);
      };
    
    return (
        <div className={classes.join(" ")} > 
            <DropdownMenu
                menu_list={actionsList}
                onVisibleChange={onVisibleChange}
                classes={menuClasses.join(" ")}
                isVisible={showMenu}
            >
                <div 
                    onClick={() => onClickInternal(!showMenu)} 
                    className={showMenu ? "open" : "close"} 
                >
                    {title}
                </div>
            </DropdownMenu>
        </div>
    );
};

export default ItemActions;