import React, { useEffect } from 'react';
import './totalproducts.scss';
import { getAllAmount } from '@entities';
import { useSelector, useDispatch } from 'react-redux';
import {
  getProductsAmountTC,
  getCurrenteSearch,
  getPaginationAmount,
  getCurrentProducts,
  SET_ENTIRE_AMOUNT,
} from '@entities';
import { Text } from '@ui-kit';

const TotalProduct = () => {
  const dispatch = useDispatch();
  const totalAmount = useSelector(getAllAmount);
  const searchCurrentValue = useSelector(getCurrenteSearch);
  const paginationAmount = useSelector(getPaginationAmount);
  const products = useSelector(getCurrentProducts);

  useEffect(() => {
    dispatch(getProductsAmountTC(null, SET_ENTIRE_AMOUNT));
  }, [dispatch]);

  const renderContent = () => {
    if (searchCurrentValue) {
      const count = products.length ? paginationAmount : 0;

      return (
        <Text as="h5" className="total__wrapper result">
          There are <Text as="span">{count}</Text> results for "{searchCurrentValue}"
        </Text>
      );
    }

    return (
      <Text as="h5" className="total__wrapper">
        There are <Text as="span">{totalAmount}</Text> illustrations now!
      </Text>
    );
  };

  return renderContent();
};

export default TotalProduct;
