import React, { useState } from 'react';
import './header-navigation-mobile.scss';
import { Link, Icon, DropdownMenu } from '@ui-kit';

import { MENU_LIST } from './header-navigation.const';
import Burger from '@ui-kit/icons/menu-gray500.svg';
import BurgerClose from '@ui-kit/icons/x-gray500.svg';

const BurgerMenu = (props) => {
  const { active, onClickInternal } = props;
  
  const onVisibleChange = () => {
    onClickInternal(false);
  };

  return (
    <div className="burger__menu" > 
      <DropdownMenu
        menu_list={MENU_LIST}
        onVisibleChange={onVisibleChange}
        classes="menu-media"
        isVisible={active}
      >
        <Icon src={active ? BurgerClose : Burger} alt="burger" onClick={() => onClickInternal(!active)}/>
      </DropdownMenu>
    </div>
  );
};

const HeaderNavigationMobile = ({ headerLinks = [], className = '' }) => {
  const [showMenu, setShowMenu] = useState(false);

  const classes = ['header__navigation-mobile', ...className.split(' ')];

  const renderLink = ({ title, ico = '' }) => {
    if (ico) {
      return <Icon src={ico} />;
    }

    return title;
  };

  const onClickInternal = (value) => {
    setShowMenu(value);
  };

  return (
    <ul className={classes.join(' ')}>
      {headerLinks.map((link, index) => (
        <li key={index}>
          <Link
            key={link.title}
            to={link.path}
            theme="tertiary-gray"
            className={link.classes && link.classes}
          >
            {renderLink(link)}
          </Link>
        </li>
      ))}
      <BurgerMenu active={showMenu} onClickInternal={onClickInternal} />
    </ul>
  );
};

export default HeaderNavigationMobile;
