import React from 'react';
import './cart-empty.scss';

import {
  getShowroomsProducts,
  addProductToCartTC,
  setShowroomsProductsInCartAC,
} from '@entities';
import { useSelector, useDispatch } from 'react-redux';
import { Text, Link } from '@ui-kit';
import { SimilarProducts } from '@widgets';
import { BROWSE_SEARCH_URL } from '@app/routes';

const CartEmpty = () => {
  const dispatch = useDispatch();

  const showroomsProducts = useSelector(getShowroomsProducts);

  const addToCart = (event, product) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(addProductToCartTC(product));
    dispatch(setShowroomsProductsInCartAC({ flag: true, cart: [product] }));
  };

  return (
    <div className='cart-empty__wrapper'>
      <div className="cart-empty__container d-flex">
        <Text as="h1" className="title mb-20">
          The shopping cart is still empty
        </Text>
        <Text as="h3" className="sub__title">
          Take a look at the gallery to select products or find what you need in
          the search.
        </Text>

        <div className="cart-empty__links-item">
          <Link
            to={BROWSE_SEARCH_URL.pathname}
            theme="text"
            className="arrow-right"
            size="xl"
          >
            {' '}
            Go to gallery
          </Link>
        </div>
      </div>

      <div className="cart-empty__showrooms-container">
        <Text as="h3" className="similar__title">
          You might like this
        </Text>
        <SimilarProducts items={showroomsProducts} addToCart={addToCart} />
      </div>
    </div>
  );
};

export default CartEmpty;
