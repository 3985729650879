export const QUESTIONS = [
    {
        title: "Can I change the illustration a bit under an extended license myself?",
        description: "Yes, you can correct the illustration yourself after it’s ready. Just remember that even after changing the illustration, you can’t indicate yourself as its author, since we created the original piece.",
        value: "item1",
        active: false,
    },
    {
        title: "What license is needed to order a portrait from a photo?",
        description: "We can draw a portrait from a photo only under an exclusive license. Portraits from a photo can’t be uploaded to stock platforms, since we don’t own the rights to the original photo. That’s why we can’t work under an extended license.",
        value: "item2",
        active: false,
    },
    {
        title: "Can I draw an illustration based on the sketch made by a third person?",
        description: "Of course, we can draw an illustration based on your sketch, even if it was provided to you by a third party. But we can’t upload illustrations created with reference to a third-party sketch to stock platforms due to copyrights. So, in this case, we will work under an exclusive rate.",
        value: "item3",
        active: false,
    },
    {
        title: "Do the illustrations for the book have to be under exclusive license?",
        description: "Illustrations for the book don’t have to be drawn under exclusive license. If you don’t mind that we own the copyrights to the backgrounds and characters from your book, we are ready to work under an extended license. All illustrations created under this license will be uploaded to stock platforms.",
        value: "item4",
        active: false,
    },
    {
        title: "Can I use the BriefStock illustrations in the products I sell?",
        description: "It’s difficult to answer this question without more information. The main principle is that illustrations from stock platforms can’t constitute the core value of your product. Having any doubts whether an illustration is a core value, consider that it is. You can also contact us for advice before using the illustration in your products.",
        value: "item5",
        active: false,
    },
    {
        title: "In the same illustration, may the background and character be purchased under different licenses?",
        description: "Yes. This can save your budget and keep copyrights to the elements that are important to you. However, the elements under different licenses will initially be created in separate projects. Once everything is approved, we will combine the elements into one final illustration in one of the projects.",
        value: "item6",
        active: false,
    },
    {
        title: "Can I change the rate if the project has already been started?",
        description: "Yes, in most cases you can do that. If we started working under the extended license, but then you decide to become the owner of the illustration, we can upgrade to the exclusive license. We will calculate the difference in rates (if we have already spent some time and you have paid for it) and add it to the next payment. In case you want to change the rate - please, ask our manager. The manager will let you know if it is possible in your particular case and guide you through the process.",
        value: "item7",
        active: false,
    },
]