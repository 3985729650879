export const SET_USER_LOADING             = 'SET_USER_LOADING';
export const SET_AUTH                     = 'SET_AUTH';
export const SET_USER                     = 'SET_USER';
export const SET_CURRENT_USER             = 'SET_CURRENT_USER';
export const SET_USER_AVATAR              = 'SET_USER_AVATAR';
export const SET_NETWORK_STATUS           = 'SET_NETWORK_STATUS';
export const SET_SERVER_ERROR             = 'SET_SERVER_ERROR';
export const SET_SEARCH                   = 'SET_SEARCH';
export const SET_RESULT_SEARCH            = 'SET_RESULT_SEARCH';
export const SET_SHOWROMS_PRODUCT         = 'SET_SHOWROMS_PRODUCT';
export const DEL_SHOWROOMS_PRODUCT        = 'DEL_SHOWROOMS_PRODUCT';
export const SET_CART                     = 'SET_CART';
export const SELECT_ALL_ITEMS_CART        = 'SELECT_ALL_ITEMS_CART';
export const SELECT_ONE_ITEMS_CART        = 'SELECT_ONE_ITEMS_CART';
export const SET_LOCALE                   = 'SET_LOCALE';
export const SET_PURCHASES_LIMIT          = 'SET_PURCHASES_LIMIT';
export const SET_DEVICE_TYPE              = 'SET_DEVICE_TYPE';
export const SET_MOBILE_SCREEN            = 'SET_MOBILE_SCREEN';

export const SET_CART_PRODUCTS            = 'SET_CART_PRODUCTS';
export const SET_CART_SHOWROOMS_PRODUCTS  = 'SET_CART_SHOWROOMS_PRODUCTS';

export const SET_CART_PRODUCT             = 'SET_CART_PRODUCT';
export const DEL_CART_PRODUCT             = 'DEL_CART_PRODUCT';
export const DEL_CART                     = 'DEL_CART';
export const SET_PUBLIC_STRIPE_TOKEN      = 'SET_PUBLIC_STRIPE_TOKEN';
export const SET_PRODUCT_INVOICE          = 'SET_PRODUCT_INVOICE';
export const SET_CART_LIMIT_POPUP         = 'SET_CART_LIMIT_POPUP';
export const SET_PAYMENT_STATUS           = 'SET_PAYMENT_STATUS';
export const SET_CART_LIMIT               = 'SET_CART_LIMIT';
export const SET_ACTIVATED_EMAIL_MODAL    = 'SET_ACTIVATED_EMAIL_MODAL';
export const SET_LOGIN_MODAL_SHOW         = 'SET_LOGIN_MODAL_SHOW';
export const SET_SIGNUP_MODAL_SHOW        = 'SET_SIGNUP_MODAL_SHOW';
export const SET_RECOVERY_MODAL_SHOW      = 'SET_RECOVERY_MODAL_SHOW';
export const SET_PAYMENT_DATA             = 'SET_PAYMENT_DATA';
export const SET_USER_BILLS_DATA          = 'SET_USER_BILLS_DATA';
export const SET_BILLS_AMOUNT             = 'SET_BILLS_AMOUNT';
export const SET_PAID_PROJECTS            = 'SET_PAID_PROJECTS';
export const SET_PAID_PRODUCTS            = 'SET_PAID_PRODUCTS';
export const SET_BILLS_LOADING            = 'SET_BILLS_LOADING';
export const SET_PURCHASES_LOADING        = 'SET_PURCHASES_LOADING';
export const SET_SEARCH_PAGE              = 'SET_SEARCH_PAGE';

export const ROLES = {
    USER    : "USER",
    ADMIN   : "ADMIN",
    EXECUTOR: "EXECUTOR"
}

export const SUB_ROLES = {
    INTERVIEWER  : "INTERVIEWER",
    ILLUSTRATOR  : "ILLUSTRATOR",
    SKETCHER     : "SKETCHER",
    ART_DIRECTOR : "ART_DIRECTOR",
}

