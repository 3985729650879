import React from 'react';
import { useDispatch } from 'react-redux';

import './modal-signup.scss';
import { SignupForm } from '@widgets';
import { setModalSignupShowAC } from '@entities';
import { Icon } from '@ui-kit';
import CloseIcon from '@ui-kit/icons/x-gray700.svg';

const ModalSignup = () => {
  const dispatch = useDispatch();

  const onBackgroundClick = (e) => {
    if (e.target.id === 'signup-background') {
      dispatch(setModalSignupShowAC(false));
    }
  };

  const closeModal = () => {
    dispatch(setModalSignupShowAC(false));
  };

  return (
    <div 
      id="signup-background" 
      className="signup-modal"
      onMouseDown={onBackgroundClick}
    >
      <div className="signup-modal__wrapper">
        <SignupForm />
        <div className="modal__close" onClick={closeModal}>
          <Icon src={CloseIcon} alt="close" />
        </div>
      </div>
    </div>
  );
};

export default ModalSignup;
