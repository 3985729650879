import { Link } from '@ui-kit';
import User from '@ui-kit/icons/user-gray700.svg';
import IllustrationGray from '@ui-kit/icons/image-gray700.svg';
import InfoSmal from '@ui-kit/icons/help-circle-gray700.svg';
import CartSmal from '@ui-kit/icons/cart-gray700-sm.svg';
import FileIcon from '@ui-kit/icons/file-gray700.svg';

import {  
  CONTACTS_URL,
  ADMIN_PURCHASES_URL,
  ADMIN_USER_SETTINGS_URL,
  ADMIN_USER_PROJECTS_URL,
  ADMIN_PROJECTS_URL,
  ADMIN_USER_BILLS_URL,
} from "@app/routes";

export const MENU_LIST = [
  {component: (
      <Link to={ADMIN_USER_SETTINGS_URL} theme="text">
        View profile
      </Link>
    ),
    icon: User,
    isDevider: true
  },
  /*{component: (
    <Link to={ADMIN_FAVORITES_URL} theme="text">
      My favorites
    </Link>
    ),
    icon: HeartIcon
  },*/
  {component: (
      <Link to={ADMIN_PURCHASES_URL} theme="text">
        My purchases
      </Link>
    ),
    icon: CartSmal
  },
  {component: (
      <Link to={ADMIN_USER_PROJECTS_URL} theme="text">
        My projects
      </Link>
    ),
    icon: IllustrationGray,
  },
  {component: (
    <Link to={ADMIN_USER_BILLS_URL} theme="text">
      My bills
    </Link>
    ),
    icon: FileIcon,
    isDevider: true
  },
  {component: (
      <Link to={CONTACTS_URL} theme="text">
        Support
      </Link>
    ),
    icon: InfoSmal,
    isDevider: true
  }
];

export const MENU_LIST_ADMIN = [
  {component: (
      <Link to={ADMIN_USER_SETTINGS_URL} theme="text">
        View profile
      </Link>
    ),
    icon: User,
    isDevider: true
  },
  /*{component: (
    <Link to={ADMIN_FAVORITES_URL} theme="text">
      My favorites
    </Link>
    ),
    icon: HeartIcon
  },*/
  {component: (
      <Link to={ADMIN_PURCHASES_URL} theme="text">
        My purchases
      </Link>
    ),
    icon: CartSmal
  },
  {component: (
      <Link to={ADMIN_PROJECTS_URL} theme="text">
        My projects
      </Link>
    ),
    icon: IllustrationGray,
  },
  {component: (
    <Link to={ADMIN_USER_BILLS_URL} theme="text">
      My bills
    </Link>
    ),
    icon: FileIcon,
    isDevider: true
  },
  {component: (
      <Link to={CONTACTS_URL} theme="text">
        Support
      </Link>
    ),
    icon: InfoSmal,
    isDevider: true
  }
];

  
export const MENU_LANG_LIST = [
  {component: (
    <div id="en" className="link">
      English
    </div>
  )},
  {component: (
    <div id="ru" className="link">
      Russian
    </div>
  )},
];
