import axios from 'axios';
import { getCookie } from '@lib/utils';
import { userService } from '@entities';

class BaseService {
  baseURL;
  instance;

  constructor() {
    this.baseURL = process.env.REACT_APP_BASE_URL;

    this.instance = axios.create({
      baseURL: this.baseURL,
      withCredentials: true,
    });

    this.instance.defaults.withCredentials = true;
  }

  generateQueryString(props) {

    props = props || {};

    if (typeof props === 'string' || props instanceof String) {
      return props.replace('?', '');
    }

    if (Array.isArray(props)) {
      props = Object.reduce((result, prop) => Object.assign(result, prop), props);
    }

    return new URLSearchParams(props);

    // let queryString = '';
    // let keysArray = [];

    // if (Array.isArray(props)) {
    //   keysArray = props;
    // } else {
    //   const keys = Object.keys(props);
    //   keys.forEach((key) => keysArray.push({ [key]: props[key] }));
    // }

    // keysArray.forEach((item, indx) => {
    //   const itemKey = Object.keys(item);

    //   queryString = queryString.concat(itemKey, '=', item[itemKey]);

    //   if (indx !== keysArray.length - 1) {
    //     queryString = queryString.concat('&');
    //   }
    // });

    // return queryString;
  }
}
export class ApiService extends BaseService {
  constructor() {
    super();

    try {
      this.instance.interceptors.request.use(this.authInterceptor);
      this.instance.interceptors.response.use(
        this.handleResponseInterceptor,
        this.handleResponseInterceptorError
      );
    } catch (e) {}
  }

  authInterceptor(config) {
    const setHeaders = () => {
      const contentType = 'application/json';

      const isFormData = config.headers['Content-Type'] === "multipart/form-data" && true;

      const headers =  {
        Authorization   : `Bearer ${localStorage.getItem('token')}`,
        'CSRF-Token'  : `${getCookie('XSRF-TOKEN') || ''}`,
      };

      if (!isFormData) {
        headers['Accept']       = contentType;
        headers['Content-Type'] = contentType;
      }

      return headers;
    };

    config.headers = setHeaders();

    return config;
  }

  handleResponseInterceptor(response) {
    return response;
  }

  async handleResponseInterceptorError(error) {
    const status = error?.response?.status || null;

    if (!status) {
      throw new Error('Status in response not found');
    }

    switch (status) {
      case 500:
        //alert ("500")
        break;
      case 400:
        throw error;
      case 401:
        await userService.refreshToken();
        break;

      //case 404:
        //window.location.replace("/404");  
      //  break;
      
      case 403:
        throw error;
        
      default:
        throw error;
    }

    return;
  }
}
