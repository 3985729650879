import React, { useEffect, useState, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import './job-form.scss';

import { useForm } from '@shared';
import { Button, Input, Text, Link, Icon, RadioButtons, Tooltip } from '@ui-kit';
import {
  getServerError, 
  setServerErrorsAC,
  sendJobFormTC,
} from '@entities';
import { TypingForm } from '@features';
import { VACANCIES } from '../../career/vacancies/vacancies.consts';
import onJobFormValid from './job-validation.hook';
import onCaptchaValid from '../security-form/captcha-validation.hook';
import { LoadingAdmin } from '@shared/ui';
import { CAREER_URL } from '@app/routes';
import EmailIcon from '@ui-kit/icons/mail-gray500.svg';
import HelpIcon from '@ui-kit/icons/help-circle-gray400.svg';
import AlertRedIcon from '@ui-kit/icons/alert-circle-error500.svg';
import { Recapcha } from '@shared';

const JobApplicationForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const recaptchaRef = createRef();
    const serverError = useSelector(getServerError);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [radioItem, setRadioItem] = useState('');

    const SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;
    const vacanciesArray = VACANCIES.map((item, index) => {
        return {
            value: index, 
            title: <FormattedMessage id={item.id + "title"} defaultMessage={item.title}/>, 
            isActive: item.value === radioItem
        }
    });
    vacanciesArray.forEach((item) => {
        item.isActive = item.value === radioItem;
    });
    
    const user = {
        email: '',
        firstName: '',
        lastName: '',
        full_name: '',
        link: '',
        link_task: '',
        about: '',
        role: '',
    };

    const { formData, onChangeInput, onSubmitForm } = useForm(
        user,
        handlerBeforeSubmitingForm
    );

    useEffect(() => {
        return () => dispatch(setServerErrorsAC([]));
    }, [dispatch]);

    useEffect(() => {
        const errorsArr = Object.keys(errors)
        if (errorsArr.length) {
            const inputElement = document.getElementsByName(errorsArr[0])[0];
            inputElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        };
    }, [errors]);

    /*
    * 'function declaration' is necessary to call after describing the hook
    */
    async function handlerBeforeSubmitingForm() {
        const vacancyRole = vacanciesArray.filter(item => item.isActive)[0];
        formData['role'] = vacancyRole?.title;

        const formErrors = onJobFormValid(formData);

        if (Object.keys(formErrors).length) {
        return setErrors(formErrors);
        }

        recaptchaRef.current.execute();
        setErrors({});        
    }

    const onSecuritySuccess = async (token) => {
        formData['g-recaptcha-response'] = token;

        recaptchaRef.current.reset();
        setIsLoading(true);

        const isResult = await dispatch(sendJobFormTC(formData));

        if (!isResult) {
        return setIsLoading(false);
        }

        return navigate(-1);
    };

    const onSecurityError = () => {
        const captchaError = onCaptchaValid({ captchToken: null });

        setErrors({ ...errors, ...captchaError });
    };

    const onRecaptchaChange = (token) => {
        if (!token) {
        onSecurityError();
        }

        return  onSecuritySuccess(token);
    };

    const onFormChange = (text) => {
        formData['about'] = text;
    };

    const checkItem = (key) => {
        setRadioItem(key);
    }

    const renderHelpIcons = () => {
        let icon = HelpIcon;

        if (errors?.address) {
            icon = AlertRedIcon;
        }

        return <Icon src={icon} alt="help__icon" className="help__icon" />;
    };

    const linkTooltipContent = (
        <ul>
            <li>
                <Text as="h6" className="tolltipe__subtitle mb-14">
                    Если ты иллюстратор и у тебя нет портфолио, то загрузи свои лучшие
                    работы в облако и предоставь ссылку. Убедись, что в портфолио
                    присутствуют векторные проекты, демонстрирующие навыки в 
                    Adobe Illustrator.
                </Text>
            </li>
            <li>
                <Text as="h6" className="tolltipe__subtitle">
                    Если ты дизайнер, вектор не обязателен. Прикрепи свое дизайнерское 
                    портфолио. Не добавляй ресурсы, не связанные с дизайном.
                </Text>
            </li>
        </ul>
    );

    const returnPath = () => {
        const fullPath = localStorage.getItem('career_path');
        if (!fullPath) {
            return CAREER_URL.pathname + '?lang=ru';
        }

        const isVacancyUrl = fullPath.includes('/career/');
        if(!isVacancyUrl) {
            return CAREER_URL.pathname + '?lang=ru';
        }

        return fullPath;
    }

    return (
    <div className={`job__form-container ${isLoading && 'pr'}`}>
        {isLoading && <LoadingAdmin />}
        <div className="form__header">
            <Text as="h2" className="form__header-title">
                Личные данные
            </Text>
        </div>
        <div className="form__content">
            <form onSubmit={onSubmitForm} className="job__form">
                <div className="job__name-block mb-43">
                    <div className="job__input">
                        <Input 
                            placeholder="Впиши своё имя"
                            name="firstName"
                            type="text"
                            //required
                            label="Имя *"
                            value={formData?.firstName || ''}
                            onChange={onChangeInput}
                            className={errors.firstName && 'form-item__error'} 
                        />
                        {errors.firstName && (
                            <Text as="span" className="error">
                            {errors.firstName}
                            </Text>
                        )}
                    </div>
                    <div className="job__input">
                        <Input
                            placeholder="Впиши свою фамилию"
                            name="lastName"
                            type="text"
                            label="Фамилия *"
                            //required
                            value={formData?.lastName || ''}
                            onChange={onChangeInput}
                            className={errors.lastName && 'form-item__error'}
                        />
                        {errors.lastName && (
                            <Text as="span" className="error">
                            {errors.lastName}
                            </Text>
                        )}
                    </div>
                </div>
                <div className="job__input-block mb-43">
                    <Input
                        placeholder="Впиши свой email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        //required
                        label="Email *"
                        iconLeft={EmailIcon}
                        value={formData?.email || ''}
                        onChange={onChangeInput}
                        className={errors.email && 'form-item__error'}
                    />
                    {errors.email && (
                        <Text as="span" className="error">
                        {errors.email}
                        </Text>
                    )}
                </div>
                <div className="job__input-block mb-43 pr-32">
                    <Input
                        placeholder="www.loremipsum.com"
                        name="link"
                        type="url"
                        //required
                        label="Ссылка на портфолио или CV *"
                        value={formData?.link || ''}
                        onChange={onChangeInput}
                        className={errors.link && 'form-item__error'}
                    />
                    <Tooltip
                        position="top"
                        direction="revers"
                        ancorPosition="anchor-right-bottom"
                        content={linkTooltipContent}
                    >
                        {renderHelpIcons()}
                    </Tooltip>
                    {errors.link && (
                        <Text as="span" className="error">
                        {errors.link}
                        </Text>
                    )}
                </div>
                <div className="job__input-block mb-24">
                    <Input
                        placeholder="www.loremipsum.com"
                        name="link_task"
                        type="url"
                        label="Ссылка на тестовое задание"
                        value={formData?.link_task || ''}
                        onChange={onChangeInput}
                        className={errors.link_task && 'form-item__error'}
                    />
                    <div className="d-grid mt-6">
                        <Text as="span" className="description">
                            Следуй условиям теста, ознакомившись по {" "}
                            <Link to={returnPath()} theme="link-color" size="sm">
                                ссылке
                            </Link>
                        </Text>
                        {errors.link_task && (
                            <Text as="span" className="error">
                            {errors.link_task}
                            </Text>
                        )}
                    </div>
                </div>

                <div className="job__input-block mb-24">
                    <Text as="label" className="label">
                        Расскажи о себе *
                    </Text>
                    <TypingForm
                        value={formData?.about || ''}
                        //required
                        onChange={onFormChange}
                        className="job__typing-form"
                        placeholder="Можешь указать всё, что считаешь нужным"
                        name="about"
                    />
                    {errors.about && (
                        <Text as="span" className="error">
                            {errors.about}
                        </Text>
                    )}
                </div>
                
                <div className="job__vacancies-block">
                    <Text as="label" className="job__vacancies-title">
                        Укажи желаемую вакансию *
                    </Text>
                    <RadioButtons 
                        radioItems={vacanciesArray} 
                        checkItem={checkItem}
                        name="role"
                    />
                    {errors.role && (
                        <Text as="span" className="error">
                            {errors.role}
                        </Text>
                    )}
                </div>

                <Button type="submit" className="mt-24" size="lg">
                    Отправить
                </Button>

                <Recapcha
                    recaptchaRef={recaptchaRef}
                    className="g-recaptcha"
                    sitekey={SITE_KEY}
                    onChange={onRecaptchaChange}
                    onErrored={onSecurityError}
                    size= "invisible"
                    //badge="inline"
                />
            </form>

            {errors.captcha && (
            <Text as="span" className="error">
                {errors.captcha}
            </Text>
            )}
            {/* server errors block */}
            {!!serverError.length && (
                <div className="server__error">
                    {serverError.map((error) => (
                        <Text as="span" key={error.param} className="error">
                            {error.msg}
                        </Text>
                    ))}
                </div>
            )}
        </div>
        <div className="job__footer">
            <Text as="span">
                Не присылай архивы и файлы для скачивания, мы не сможем скачать ваши файлы. Спасибо!
            </Text>
        </div>
    </div>
  );
};

export default JobApplicationForm;
