import { checkVAT, countries } from 'jsvat';
export class Validation {
  instance;
  error;

  constructor(validationObject) {
    this.instance = validationObject;
    this.error = [];
  }

  isRequired(name) {
    if (!this.instance.field) {
      this.error = [...this.error, `${this.instance.title} is required.`];
    }

    return this;
  }

  isMoreThanZero() {
    if (!this.instance.field) {
      this.error = [
        ...this.error,
        `${this.instance.title} must be greater than 0`,
      ];
    }

    return this;
  }

  isValidTextarea() {
    if (!/^[A-ZА-Я0-9Ёё,.'"!?():@_-\s]+$/i.test(this.instance.field)) {
      this.error = [...this.error, 'Unacceptable symbols.'];
    }

    return this;
  }

  isValidCharacters() {
    if (!/^[A-ZА-Я0-9._-]+$/i.test(this.instance.field)) {
      this.error = [...this.error, 'Unacceptable symbols.'];
    }

    return this;
  }

  isEmail() {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.instance.field)) {
      this.error = [...this.error, `${this.instance.title} is not valid.`];
    }
    return this;
  }

  isAddress() {
    if (this.instance.field && !/^[A-Z0-9()#№/\\,.-\s]+[A-Z0-9.]$/i.test(this.instance.field)) {
      this.error = [...this.error, `${this.instance.title} is not valid.`];
    }

    return this;
  }

  isValidVAT(country) {
    const checkCountry = countries.filter(
      item => item.codes.indexOf(country) >= 0
    )[0];
    if (!checkCountry) {
      return this;
    }

    const result = checkVAT(this.instance.field, [checkCountry]);
    if(!result.isValid) {
      this.error = [...this.error, `${this.instance.title} is not valid.`];
    };

    return this;
  }

  isUrl() {
    const value = this.instance.field;
    const link = !value.includes('http') ? 'http://' + value : value;

    const regex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i
    if (!regex.test(link)) {
      this.error = [...this.error, `${this.instance.title} is not valid.`];
    } else {
      this.instance.field = link;
    }

    return this;
  }

  minLenght(value) {
    if (this.instance.field.length < value) {
      this.error = [
        ...this.error,
        `Please enter at least ${value} characters.`,
      ];
    }

    return this;
  }

  maxLenght(value) {
    if (this.instance.field && this.instance.field.length > value) {
      this.error = [
        ...this.error,
        `Your ${this.instance.title} is too long. Max - ${value}`,
      ];
    }

    return this;
  }

  fileNameLength(fileName, value) {
    if (fileName?.length > value) {
      this.error = [
        ...this.error,
        `${this.instance.title} name must not exceed 50 letters.`,
      ];
    }

    return this;
  }

  containLettersAndNumbers() {
    const regexpNumbers = new RegExp(/[0-9]/, 'g');
    const regexpLetters = new RegExp(/[a-z]/, 'gi');
    const regexpUnicode = new RegExp(/[\p{Lt}\p{Lm}\p{Lo}\p{M}\p{Z}\p{Sk}\p{So}\p{Nl}\p{No}\p{C}]/, 'giu');

    const consistNumber = regexpNumbers.test(this.instance.field);
    const consistString = regexpLetters.test(this.instance.field);
    const consistUnicode = regexpUnicode.test(this.instance.field);

    if (!consistNumber || !consistString) {
      this.error = [
        ...this.error,
        `Your ${this.instance.title} must contain english letters and numerals`,
      ];
    }

    if (consistUnicode) {
      this.error = [
        ...this.error,
        `Your ${this.instance.title} must not contain unsupported characters`,
      ];
    }

    return this;
  }

  canNotBeZero() {
    if (this.instance.field <= 0) {
      this.error = [...this.error, `${this.instance.title}`];
    }

    return this;
  }

  getErrors() {
    return this.error;
  }
}
