import React, {createRef, useState, useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Recapcha } from '@shared';

import { useForm } from "@shared";
import { Input, Button, Text } from "@ui-kit";
import { sendSubscribeFormTC, getServerError, setServerErrorsAC } from "@entities";
import onSubscribeFormValid from "./subscribe-validation.hook";
import onCaptchaValid from "../security-form/captcha-validation.hook";
import EmailIcon from '@ui-kit/icons/mail-gray500.svg';

const SubscribeForm = () => {
    const dispatch = useDispatch();
    const recaptchaRef = createRef();
    const serverError = useSelector(getServerError);
    const [captcha, setCaptcha] = useState(null);
    const [errors, setErrors] = useState({});
    const SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;

    const { formData, onChangeInput, onSubmitForm } = useForm(
        {name: "", email: ""},
        handlerBeforeSubmitingForm
    );

    useEffect(() => {
        return () => dispatch(setServerErrorsAC([]));
    }, [dispatch]);

    useEffect(() => {
        const errorsArr = Object.keys(errors)
        if (errorsArr.length) {
            const inputElement = document.getElementsByName(errorsArr[0])[0];
            inputElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        };
    }, [errors]);

    async function handlerBeforeSubmitingForm() {
        const formErrors = onSubscribeFormValid(formData);

        if (Object.keys(formErrors).length) {
        return setErrors(formErrors);
        }

        setErrors({});
        recaptchaRef.current.execute();
    }

    const onSecuritySuccess = async (token) => {
        formData.captchToken = token;
        recaptchaRef.current.reset();

        dispatch(sendSubscribeFormTC(formData));
    };

    const onSecurityError = () => {
        const captchaError = onCaptchaValid({ captchToken: null });

        setErrors({ ...errors, ...captchaError });
    };

    const onRecaptchaChange = (token) => {
        setCaptcha(token);

        if (!token) {
            onSecurityError();
        }

        return onSecuritySuccess(token);
    };

    return (
        <>
            <form onSubmit={onSubmitForm} className="subscribe__form">
            <Input
                type="text"
                name="name"
                autoComplete="given-name"
                required
                placeholder="Enter your name"
                value={formData?.name || ""}
                onChange={onChangeInput}
                className={errors?.name ?' form-item__error':''}
            />
            {errors.name && (
                <Text as="span" className="error">
                {errors.name}
                </Text>
            )}
            <Input
                placeholder="Enter your email"
                name="email"
                type="email"
                autoComplete="email"
                required
                iconLeft={EmailIcon}
                value={formData?.email || ""}
                onChange={onChangeInput}
                className={`mt-8${errors?.email ?' form-item__error':''}`}
            />
            {errors.email && (
                <Text as="span" className="error">
                {errors.email}
                </Text>
            )}
            <Button size="lg" className="mt-20">Get a set</Button>

            {<Recapcha
                recaptchaRef={recaptchaRef}
                className="g-recaptcha"
                sitekey={SITE_KEY}
                onChange={onRecaptchaChange}
                onErrored={onSecurityError}
                hidden={captcha !== null}
                size="invisible"
            />}
            </form>
            {errors.captcha && (
                <Text as="span" className="error">
                {errors.captcha}
                </Text>
            )}
            {/* server errors block */}
            {!!serverError.length && (
                <div className="server__error">
                    {serverError.map((error) => (
                    <Text as="span" key={error.param} className="error">
                        {error.msg}
                    </Text>
                    ))}
                </div>
            )}
        </>
    );
};

export default SubscribeForm;
