import { v4 as uuidv4 } from 'uuid';
import './textarea.scss';
import { Text, Icon } from '@ui-kit';
import InfoRedIcon from '@ui-kit/icons/alert-circle-error500.svg';

const TextArea = (props) => {
  const {
    hint = '',
    className = '',
    errorText = '',
    isError = false,
    placeholder = '',
    isDisabled = false,
    type = '',
    onChange = () => {},
    label,
    position,
    iconRight,
    iconLeft,
    icoLeft,
    onIconClick,
    children,
    maxLengthCustom,
    minLengthCustom,
    ...options
  } = props;

  let typeTextArea = type;
  const labelId = uuidv4();
  let classes = ['textarea__container'];

  if (iconRight) {
    classes.push('icon__right');
  }

  if (iconLeft) {
    classes.push('icon__left');
  }

  if (type === 'url') {
    typeTextArea = 'text';
    classes.push('url');
  }

  if (isError) {
    classes.push('error');
  }

  if (isDisabled) {
    classes.push('disabled');
  }

  classes = [...classes, ...className.split(' ')];

  const handler = (event) => {
    const value = event.target.value;

    if (maxLengthCustom) {
      if (checkMaxLength(value)) {
        event.preventDefault();
        return false;
      }
    }

    if (minLengthCustom) {
      if (checkMinLength(value)) {
        event.preventDefault();
        return false;
      }
    }

    onChange(event);
  };

  const checkMaxLength = (value) => {
    return +value > maxLengthCustom;
  };

  const checkMinLength = (value) => {
    if (+value === 0) {
      return false;
    }

    return +value < minLengthCustom;
  };

  return (
    <>
      <div className={classes.join(' ')}>
        <div className="textarea__children pr">
          {iconLeft && (
            <div className="icon__left-icon"> {<Icon src={iconLeft} />}</div>
          )}
          {label && <Text as="h6" htmlFor={labelId} className="label">{label}</Text>}
          <div className="textarea__line">
            {type === 'url' && (<div className="textarea__url">http://</div>)}
            <textarea
              {...options}
              type={typeTextArea}
              id={labelId}
              placeholder={placeholder}
              className="textarea"
              onChange={handler}
            />
          </div>

          {iconRight && !isError && (
            <div className="icon__right-icon" onClick={onIconClick}>
              {<Icon src={iconRight} />}
            </div>
          )}
          {isError && (
            <div className="icon__left-icon">{<Icon src={InfoRedIcon} />}</div>
          )}
        </div>
      </div>
      {hint && !errorText && (
        <Text as="h6" className="textarea__text-hint ">
          {hint}
        </Text>
      )}
      {errorText && (
        <Text as="h6" className="textarea__text-error">
          {errorText}
        </Text>
      )}
    </>
  );
};

export default TextArea;

/*  
<TextArea
    className="pagination__field"
    label={'hello'}
    iconRight={mail}
    iconLeft={mail}
    placeholder="sadasdas"
    hint="This is a hint text to help user."
    isError={true}
    maxLengthCustom ={5}
    minLengthCustom = {1}
    isDisabled={true}
    errorText="This is an error message."
/> 
*/
