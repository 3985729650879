import React from 'react';
import './userInfoMenu.scss';
import { Text } from '@ui-kit';

export const UserInfoMenu = ({ setActive = ()=>{}, isActive }) => {
  const setActiveClass = (flag) => {
    if (flag === isActive) {
      return 'active';
    }
  };

  return (
    <div className="user__info">
      <div className="user__info-menu d-flex">
        <Text
          as="h6"
          className={`user-menu__item ${setActiveClass('brief')}`}
          onClick={() => setActive('brief')}
        >
          Brief
        </Text>
        <Text
          as="h6"
          className={`user-menu__item ${setActiveClass('info')}`}
          onClick={() => setActive('info')} 
        >
          Info
        </Text>
        <Text
          as="h6"
          className={`user-menu__item ${setActiveClass('faq')}`}
          onClick={() => setActive('faq')}
        >
          FAQ
        </Text>
      </div>
    </div>
  );
};

export default UserInfoMenu;
