import React, { memo, useRef, useState } from 'react';
import './message.scss';
import parse from 'html-react-parser';
import { Text, Avatar, Icon } from '@ui-kit';
import { dateLib, geShortCut } from '@shared';
import { uploadBinaryFileTC } from '@entities';
import { useDispatch } from 'react-redux';
import Magnifying from '@ui-kit/icons/zoom-in-gray700.svg';
import Download from '@ui-kit/icons/download-gray700.svg';
import CloseIcon from '@ui-kit/icons/x-white.svg'

const { REACT_APP_PROJECTS_URL } = process.env;

const Message = ({ message = {}, ownerId = '', isUser }) => {
  const dispatch = useDispatch();
  const { text, creatorId, createdAt, messageType, type } = message;

  const isAvaCombine = !message?.isAvaCombine;
  const isMessageBot = messageType && messageType === 'botMessage';
  const isImageMessage = type === 'img';
  let isCreaterIsOwner = creatorId.id === ownerId;
  const classes = ['message__container'];
  let avatar;

  if (isCreaterIsOwner) {
    classes.push('message__right');
  }

  if (!isAvaCombine && isUser) {
    classes.push('margin-left');
  }

  if (!isAvaCombine && !isUser) {
    classes.push('margin-right');
  }

  if (isMessageBot && !isUser) {
    classes.push('message__right');
    isCreaterIsOwner = true;
  }

  if (isImageMessage) {
    classes.push('message-img__wrapper');
  }

  if (isAvaCombine) {
    avatar = isMessageBot ? (
      <Avatar
        size="lg"
        className="blue-light"
        srcStatic={creatorId.ava}
        status={true}
        isUserOnline={true}
      />
    ) : (
      <Avatar
        size="lg"
        src={creatorId.ava}
        text={geShortCut(creatorId?.firstName, creatorId?.lastName)}
      />
    );
  }

  const saveImage = async (event, id) => {
    event.stopPropagation();
    // const attachment = JSON.parse(message.attachments);
    const attachment = message.attachments;
    const source = attachment[id];
    const name = source.split('/')[2];
    const dir = source.replaceAll(`/${name}`, "");

    const file = await dispatch(uploadBinaryFileTC({dir, name}));

    return downloadFile(file);
  }

  const downloadFile = (blob) => {
    const link = document.createElement('a');
    link.setAttribute('download', blob.name);

    const href = URL.createObjectURL(blob);
    link.href = href;
    link.setAttribute('target', '_blank');
    link.click();
    URL.revokeObjectURL(href);
  }

  const renderMessageContent = () => {
    if (!isImageMessage) return parse(text);

    // this logic for different type of messages
    if (isImageMessage) {
      const attachments = message.attachments || [];

      // if (typeof attachments === 'string') {
      //   attachments = JSON.parse(attachments);
      // }

      return (
        <div className={`message-image__container`}>
          {attachments.map((file, index) => (
            <div
              className="message-image__content"
              key={`message-image-${index}`}
            >
              <MessageImageBackground 
                src={`${REACT_APP_PROJECTS_URL}/${file}`} 
                saveImage={(event) => saveImage(event, index)}
              />
              <Icon
                src={`${REACT_APP_PROJECTS_URL}/${file}`}
                alt={"message-file"}
                className="message-image__message"
              />
              <div className="message__addition">
                <Text as="h6" className="message__time">
                  {dateLib.fromDateToDatemonthPMAM(createdAt)}
                </Text>
              </div>
            </div>
          ))}
        </div>
      );
    }
  };

  const defineCreator = () => {
    if (isCreaterIsOwner && !isMessageBot) {
      return (
        <Text as="h6" className="message__text-owner">
          You
        </Text>
      );
    }

    return (
      <Text as="h6" className="message__text-owner creator">
        {creatorId?.firstName || ''} {creatorId?.lastName || ''}
      </Text>
    );
  };

  return (
    <div className="message__wrapper">
      {!isCreaterIsOwner && <div className="message__ava">{avatar}</div>}
      <div className={classes.join(' ')}>
        {defineCreator()}
        {renderMessageContent()}
        {!isImageMessage && (
          <div className="message__addition">
            <Text as="h6" className="message__time">
              {dateLib.fromDateToDatemonthPMAM(createdAt)}
            </Text>
          </div>
        )}
      </div>
      {isMessageBot && !isUser && avatar}
    </div>
  );
};

const MessageImageBackground = ({src, saveImage, callback}) => {
  const messageMenuRef = useRef();
  const [ isOpen, setIsOpen ] = useState(false);
  const classes = ['message-image__background'];

  if (isOpen) {
    classes.push('open');
}

  const onClick = (event) => {
    event.stopPropagation();
    const cursorPosition = event;

    if (event.target.tagName === "LI") {
     setTimeout(()=> {messageMenuRef.current.classList.remove('show')}, 500);

      return saveImage();
    }

    if (!cursorPosition?.clientX) {
      return;
    }

    const MARGIN = 5;

    const {clientY, clientX} = cursorPosition;
    messageMenuRef.current.classList.add('show');
    messageMenuRef.current.style.top= clientY + MARGIN +"px";
    messageMenuRef.current.style.left= clientX - messageMenuRef.current.offsetWidth - MARGIN +"px";
  };

  const onMouseLeave =() => {
    setTimeout(()=> {messageMenuRef.current.classList.remove('show')}, 500);
  }

  const openModal = (event) => {
    event.stopPropagation();
    setIsOpen(true);
  }

  const closeModal = (event) => {
    event.stopPropagation();
    setIsOpen(false);
  };

  return (
    <div className="message-image" onClick={openModal}>
      <div className="message-image__hover">
        <div className='message-image__icon'>
          <Icon src={Magnifying} />
        </div>
      </div>
      <div className={classes.join(' ')} onClick={closeModal}>
        <div className="message-image__image">
          <Icon 
            src={src}
            onClick={onClick}
            alt=""
          />
        </div>
        <div className="modal__top-panel">
          <div className="modal__close">
            <Icon src={CloseIcon} onClick={closeModal} />
          </div>
        </div>
        <ActionDropDown 
          onMouseLeave ={onMouseLeave} 
          customref={messageMenuRef} 
          saveImage={saveImage}/>
      </div>
    </div>
  )
};

const ActionDropDown = ({customref, saveImage, onMouseLeave}) => {
  return (
    <ul 
      className='message-image__dropdown' 
      onMouseLeave={onMouseLeave} 
      ref={customref}
    >
      <li className='message-image__dropdown-item' onClick={saveImage}>
        <Icon src={Download}/>
        <Text as="h4">Save as...</Text>
      </li>
    </ul>
)}

export default memo(Message);
