import React, { useState } from 'react';
import './similar-products.scss';
import Slider from 'react-slick';
import { CarouselArrow, Icon } from '@ui-kit';
import { BROWSE_URL } from '@app/routes';
import { HoverProductCard } from '@widgets';

const { REACT_APP_PRODUCTS_URL } = process.env;

const SimilarProducts = ({ items = [], addToCart = ()=>{} }) => {
  const [lastSlide, setLastSlide] = useState(true);
  const [firstSlide, setFirstSlide] = useState(false);

  const sliderSettings = {
    infinite: true,
    variableWidth: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    prevArrow: <CarouselArrow direction="prev" />,
    nextArrow: <CarouselArrow direction="next" />,
    responsive: [
      {
        breakpoint: 606,
        settings: {
          prevArrow: <CarouselArrow direction="prev" isDisabled={!firstSlide}/>,
          nextArrow: <CarouselArrow direction="next"  isDisabled={!lastSlide}/>,
          afterChange: (currentSlide) => {
            if (currentSlide+1 === items.length-1) {
              setLastSlide(false);
            } else {
              setLastSlide(true);
            };
            if (currentSlide) {
              setFirstSlide(true);
            } else {
              setFirstSlide(false);
            };
          },
        }
      }
    ],
  };

  if (!items.length) {
    return;
  }

  return (
    <div className="similar__container mt-12">
      <div className="similar__slider pr">
        <Slider {...sliderSettings}>
          {items.map((slide, index) => (
            <div
              key={Date.now() + index}
              className="similar__item mr-8 slide__item pr background__static"
            >
              <a
                href={`${BROWSE_URL.pathname}/${slide.linkId}-${slide.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon
                  className="similar__img"
                  src={`${REACT_APP_PRODUCTS_URL}/${slide.pathToBrowse}`}
                  alt="similar_products"
                />

                {slide.user && (
                  <HoverProductCard
                    inCart={slide?.inCart || null}
                    author={slide.user}
                    addToCart={(e) => addToCart(e, slide)}
                  />
                )}
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default SimilarProducts;
