import React from "react";
import './search-form-bg.scss';
import { Input, Button } from '@ui-kit';
import SearchIcon from "@ui-kit/icons/search-gray500.svg";
import ResetIcon from "@ui-kit/icons/x-gray500.svg";

const SearchFormBg = (props) => {
    const {
        onSubmit, 
        onChange, 
        value = '', 
        placeholder = '', 
        className = '', 
        ...options
    } = props;
    const classes = ['search__container_withbg', ...className.split(' ')];

    const resetSearch = (event) => {
        onChange(event);
        onSubmit(event);
    }

    return (
        <form onSubmit={onSubmit} className={classes.join(' ')}>
            <Input 
                type="text"
                placeholder={placeholder}
                name="search"
                value= {value}
                iconLeft={SearchIcon}
                onChange={onChange}
                {...options}
            />
            {value && (
                <Button 
                    type="button"
                    size="xxl"
                    theme="link-color"
                    iconLeft={ResetIcon}
                    onClick={resetSearch}
                />
            )}
        </form>
    );
}

export default SearchFormBg;