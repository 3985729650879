
import ReCAPTCHA from 'react-google-recaptcha';

const Recapcha = (props) => {

  if (process.env.REACT_APP_CAPTCHA_DISABLE === 'true') {

      props.recaptchaRef.current = {

        execute: () => props.onChange('012345'),

        reset: () => {},

      };

    return;

  }

  const inheritedProps = Object.assign({}, props);

  delete inheritedProps.className;

  return (

    <div className={inheritedProps.wrapperClassName}>

      <ReCAPTCHA
        {...inheritedProps}
        ref={inheritedProps.recaptchaRef}
      />

    </div>
  );
};

export default Recapcha;
