import React, { useState, useRef, useEffect } from 'react';
import './dropdown.scss';

import { Icon, Text } from '@ui-kit';
import ArrowFlex from '@ui-kit/icons/chevron-gray500.svg';

const Dropdown = (props) => {
  const MARGIN = 8;
  const {
    id,
    data = [],
    value = '',
    onTop = false,
    search = false,
    className = '',
    placeholder = '',
    multiple = false,
    iconLeft = false,
    iconRight = false,
    showArrow = false,
    isDisabled = false,
    autoComplete = "off",
    renderOption = () => {},
    onChooseCountry = () => {},
  } = props;
  const parentInput = useRef();
  const wrapperContent = useRef();
  const dropDownContent = useRef();
  const [listData, setListData] = useState(data);
  const [inputValue, setInputValue] = useState();
  const [isFocus, setIsFocus] = useState(false);
  const [arrowCondition, setArrowCondition] = useState({
    transform: 'rotateZ(90deg) translateX(-50%)',
  });

  useEffect(() => {
    setListData(data);

    return () => setListData([]);
  }, [data, value])

  useEffect(()=> {
    const activeValue = listData.find((item) => item.value === value);

    if (!activeValue) {
      if (isFocus) return;
      return setInputValue('');
    }
 
    setInputValue(activeValue?.name);
    // eslint-disable-next-line
  }, [value]);

  const containerClasses = ['dropdown__container', 'pr', ...className];
  const inputClasses = ['input-search'];

  if (isDisabled) {
    containerClasses.push('disabled');
  }

  if (showArrow) {
    containerClasses.push('icon');
  }

  if (iconLeft) {
    containerClasses.push('icon__left');
  }

  if (iconRight) {
    containerClasses.push('icon__right');
  }

  if (!multiple) {
    inputClasses.push('not-multiple');
  }

  if (search) {
    inputClasses.push('search');
  }

  const onFocus = (e) => {
    if (e.target.id === id) {
      setListPosition();
    }

    setIsFocus(true);
    parentInput.current.select();
  };

  const searchCountry = (query, fieldName) => {
    fieldName = fieldName || 'value';

    if (query === undefined) return [];

    query = query.toLowerCase();

    return data
      .filter(item => item[ fieldName ].toLowerCase().includes(query))
      .sort((item1, item2) => {
        return item1[ fieldName ].toLowerCase().indexOf(query) - item2[ fieldName ].toLowerCase().indexOf(query);
      });
  }; 

  const setListPosition = () => {
    let viewportOffset = parentInput.current.getBoundingClientRect();
    const { top, left, width } = viewportOffset;
    const { current } = dropDownContent;
    wrapperContent.current.classList.add('active');

    if (onTop) {
      current.style.top = top - current.clientHeight - MARGIN + 'px';
    } else {
      current.style.top =
        top + parentInput.current.clientHeight + MARGIN + 'px';
    }

    current.style.left = left - 2 + 'px';
    current.style.width = width + 3 + 'px';
  };

  const onWrapperClick = (e) => {
    wrapperContent.current.classList.remove('active');
    setIsFocus(false);
  };

  const onListClick = (e) => {
    if (multiple) {
      e.stopPropagation();
    }

    setIsFocus(false);
    setArrowCondition({
      transform: 'rotateZ(90deg) translateX(-50%)',
    });
  };

  const onInputClick = (e) => {
    e.stopPropagation();

    setArrowCondition({
      transform: 'rotateZ(-90deg) translateX(50%)',
    });

    setIsFocus(true);
    parentInput.current.select();
  };

  const renderItem = (item, index) => {
    if (renderOption) {
      return renderOption(item, index);
    }

    return <Text as="h5">{item.name}</Text>;
  };

  const onChange = (event) => {
    const value = event.target.value;

    const result = searchCountry( value, 'name' );

    setInputValue(value);
    setListData(result);
    setTimeout(setListPosition, 0);
    onChooseCountry();
  };

  const defineValue = () => {
    const activeValue = listData.find((item) => item.value === value);

    if (search && isFocus) {
        return inputValue;
    }

    if (activeValue) {
      return activeValue.name;
    }

    return '';
  };

  const renderIconRight = () => {
    if (iconRight) {
      return (
        <Icon src={iconRight} className="dropdown-right__icon" />
      )
    }

    return (
      <Icon src={ArrowFlex} styles={arrowCondition} className="arrow__icon" />
    )
  }

  return (
    <div className={containerClasses.join(' ')}>
      {renderIconRight()}
      
      {iconLeft && <Icon src={iconLeft} className="dropdown-left__icon" />}
      <input
        id={id}
        type="text"
        ref={parentInput}
        onFocus={onFocus}
        onChange={onChange}
        value={defineValue()}
        disabled={isDisabled}
        onClick={onInputClick}
        placeholder={placeholder}
        className={inputClasses.join(' ')}
        autoComplete={autoComplete}
      />
      <div
        className="input__wrapper"
        ref={wrapperContent}
        onClick={onWrapperClick}
      >
        <ul
          className="input-search__list"
          ref={dropDownContent}
          onClick={onListClick}
        >
          {listData.map((item, index) => {
            return renderItem(item, index);
          })}
        </ul>
      </div>
    </div>
  );
};

export default Dropdown;

/*
 <Dropdown
        data={PRODUCT_DROPDOWN_LIST}
        showArrow={true}
        placeholder= "Sort by"
        value={sortBy}
        name="products-dropdown"
        showAvatar={true}
        renderOption={renderDropDowns}
      />
*/
