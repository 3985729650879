import React from "react";
import './accordion.scss';

import {Text, Icon} from '@ui-kit';
import MinusIcon from '@ui-kit/icons/minus-circle-gray400.svg';
import PlusIcon from '@ui-kit/icons/plus-circle-gray400.svg';

const AccordionItem = ({item={}}) => {
    return (
        <div className="accordion__container-item pr">
            <div className="accordion__ico">
                <Icon src={item.active?MinusIcon:PlusIcon}/>
            </div>
            <Text as="h4" >
                {item.title}
            </Text>
            {item.active && 
                <Text as="h5" className="mt-8">
                    {item.description}
                </Text>
            }
        </div>
    )
}

export default AccordionItem;