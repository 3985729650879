import React, { useCallback, useContext } from 'react';
import './table-projects.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  getAdminProjectTC,
  setCurrentProjectImageAC,
  setSelectedProjectAC,
  getProjectWasChanged,
  setIsUnsavedProjectShowAC,
  getCustomer,
} from '@entities';
import { Text } from '@ui-kit';
import ProjectItem from './ProjectItem';
import { ProjectsContext } from '@shared/hooks';

const TableProjects = () => {
  const dispatch = useDispatch();
  const projectIsNotSaved = useSelector(getProjectWasChanged);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentUser = useSelector(getCustomer);
  const updatedProjects = currentUser?.updatedProjects;
  const projectsContext = useContext(ProjectsContext);
  const { projects } = projectsContext;

  const onChoose = useCallback((project) => {

    if(projectIsNotSaved) {
      return dispatch(setIsUnsavedProjectShowAC(true));
    }

    window.roomId = null;
    dispatch(getAdminProjectTC(project.id));
    dispatch(setCurrentProjectImageAC(null));
    dispatch(setSelectedProjectAC(project.id));

    searchParams.set('project_asset_id', project.id);
    setSearchParams(searchParams);
    // eslint-disable-next-line
  }, [projectIsNotSaved]);

  return (
    <div className="projects__panel">
      <div className="admin__table">
        <div className="admin__table-header">
          <Text as="h6" className="project-name">
            Name
          </Text>
          <Text as="h6" className="project-stage">
            Stage
          </Text>
          <Text as="h6" className="project-notification">
            Status
          </Text>
          <Text as="h6" className="project-customer">
            Customer name
          </Text>
          <Text as="h6" className="project-illustrator">
            Illustrator
          </Text>
        </div>
        <div className="admin__table-body">
          {projects.map((item, index) => (
            <ProjectItem
              key={`admin-project-${item.id}`}
              project={item}
              onClick={onChoose}
              updatedProjects={updatedProjects}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TableProjects;
