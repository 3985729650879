import React from 'react';
import './questions.scss';

import { Text, Icon } from '@ui-kit';
import Clock from '@ui-kit/icons/clock-accent600.svg';
import Question from '@ui-kit/icons/help-circle-accent600.svg';
import Play from '@ui-kit/icons/play-accent600.svg';

const Questions = () => {
  return (
    <div className="questions__container">
      <div className="main__container questions__content">
        <div className="questions__header">
          <Text as="h1" className="mb-20">
            Frequently asked questions
          </Text>
          <Text as="h3">Have questions? We’re here to help.</Text>
        </div>
        <div className="questions__items d-flex">
          <div className="questions__item">
            <div className="questions__ico d-flex mb-22">
              <Icon src={Clock} alt="clock" />
            </div>
            <Text as="h3" className="mb-8">
              How long will my illustration take to render?
            </Text>
            <Text as="h5">
              Whether you have a team of 2 or 200, our shared team inboxes keep
              everyone on the same page and in the loop.
            </Text>
          </div>

          <div className="questions__item">
            <div className="questions__ico d-flex mb-22">
              <Icon src={Question} alt="question" />
            </div>
            <Text as="h3" className="mb-8">
              What kind of license will I get for an illustration?
            </Text>
            <Text as="h5">
              An all-in-one customer service platform that helps you balance
              everything your customers need to be happy.
            </Text>
          </div>

          <div className="questions__item">
            <div className="questions__ico d-flex questions__ico-play mb-22">
              <Icon src={Play} alt="play" />
            </div>
            <Text as="h3" className="mb-8">
              What do I need to start working on my project?
            </Text>
            <Text as="h5">
              Measure what matters with Untitled’s easy-to-use reports. You can
              filter, export, and drilldown on the data in a couple clicks.
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questions;
