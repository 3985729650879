import React, { useContext } from 'react';
import './dropdown-menu.scss';
import 'rc-dropdown/assets/index.css';

import { SearchFormContext } from '@shared/hooks';
import Dropdown from 'rc-dropdown';
import Menu, { MenuItem } from 'rc-menu';
import { Text, Icon } from '@ui-kit';
import { SearchFormDefault } from '@features';


const DropdownMenu = (props) => {
    const { 
        children = () => {},
        menu_list = [],
        onVisibleChange = () => {},
        classes = '',
        isVisible = false
    } = props;
    const searchContext = useContext(SearchFormContext);

    const renderMenuContent = (item) => {
        if (item.component && item.icon) {
            return (
                <div className="d-flex ai-center menu__item-component" onClick={onVisibleChange}>
                    <Icon src={item.icon} className="menu__item-icon" />
                    <Text as="h6">{item.component}</Text>
                </div>
            )
        }
        
        if (item.component) {
            return <div className="menu__item-component" onClick={onVisibleChange}>{item.component}</div>;
        }

        if (item.isSearch) {
            return (
                <SearchFormDefault
                    onSubmit={(e)=> { onVisibleChange(); searchContext.onSubmitSearch(e)}}
                    onChange={searchContext.onChangeSearch}
                    placeholder="Search"
                    value={searchContext.searchValue}
                />
            );
        }
    };


    const list = menu_list.map((item, index) => {
        let classes = ['menu__item'];

        if (item.isDevider) {
            classes.push('devider');
        }

        if (item.component) {
            classes.push('item__wrapper-component');
        }

        if (item.mobile) {
            classes.push('mobile');
        }

        return (
            <MenuItem className={classes.join(' ')} key={`item-menu-${index}`}>
                {renderMenuContent(item)}
            </MenuItem>
        );
  });

  const menu = (
    <Menu className={`menu-dropdown ${classes}`}>
        {list}
    </Menu>
  );

  return (
    <div>
      <Dropdown
        trigger={['click']}
        overlay={menu}
        onVisibleChange={onVisibleChange}
        visible={isVisible}
      >
        {children}
      </Dropdown>
    </div>
  );
};

export default DropdownMenu;
