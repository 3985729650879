import React, { useCallback, useState } from "react";
import './licensesQuestions.scss';

import {Text} from '@ui-kit';
import {QUESTIONS} from './questions.consts';

import {Accordion} from '@ui-kit';

const Questions = () => {
    const [accordionState, setAccordionState] = useState(QUESTIONS);

    const choosingItem = useCallback((value) => {
       const newState = accordionState.map(item => {
         item.value === value ? item.active = !item.active : item.active=false;
            return item;
       });

        setAccordionState(newState);
    }, [accordionState]);

    return (
        <div className="main__container licenses__container-questions d-flex fd-column ai-center">
            <Text as="h1" className="questions__title mb-20">FAQs</Text>
            <Text as="h3" className="questions__suptitle">
                Everything you need to know about our licenses.
            </Text>
            <Accordion onClick={choosingItem} items={QUESTIONS}/>
        </div>
    )
}

export default Questions;