import React from 'react';
import './faqInfo.scss';

import { Text } from '@ui-kit';

const FaqInfoComponent = () => {
  return (
    <div className="info-panel">
      <Text as="h3" className="faq-info__tittle">
        Questions about projects
      </Text>
      <Text as="h6" className="faq-info__suptittle">
        Here you can find out how we work before you start working with us
      </Text>
      {faqInfo.map((item, index) => (
        <FaqInfoItem key={`faq-${index}`} item={item} />
      ))}
    </div>
  );
};

const FaqInfoItem = ({ item }) => {
  const { title, description } = item;

  return (
    <div className="faq-info__item">
      <Text as="h6" className="faq-info__item-title">
        {title}
      </Text>
      <Text as="h6" className="faq-info__item-suptitle">
        {description}
      </Text>
    </div>
  );
};

const formatLink = () => {
  const zipFile = process.env.REACT_APP_FILES_URL + '/License_agreement_template.zip'
  return (
    <>
      The price of work under an exclusive license is $80/hour. This price is ideal if you don’t 
      want us to sell the illustrations later. We provide you an exclusive license under which 
      you receive all rights to the works that we will do for you. Of course, we will not 
      use the created illustration anywhere. We can also provide you with templates for both 
      license agreements if you would like to read{" "}
      <a href={zipFile} target="_blank" rel="noreferrer">detailed official descriptions</a>.
    </>
  );
};

const formatText = () => {
  return (
    <>
      -Your email; <br/>
      -your full name; <br/>
      -your address. <br/>
      In case you want to make payment as a company, then we'll need: <br/>
      -representative's name; <br/>
      -company address; <br/>
      VAT number. <br/>
      If there are any problems with an invoice, we can provide you with our bank account details 
      so that you can make a bank transfer.
    </>
  );
};

const faqInfo = [
  {
    title: `How does the project start?`,
    description:
      `Here is no prepayment: we discuss and estimate the project, receive your approval of 
      expected price and start creating the illustration for you. Once you approve the project 
      terms you agree to pay for the work performed by BriefStock. Our account manager will 
      inform you when it is time to make payment.`,
  },
  {
    title: `How many illustrations can you draw in one project?`,
    description:
      `One project per illustration. If the elements can be grouped on the same canvas and 
      look like a single entity, they can be created within the same project.`,
  },
  {
    title: `Do you work in 3D?`,
    description:
      `Our illustration may look like 3D, but it will be a vector file.`,
  },
  {
    title: `Why are payments made in stages?`,
    description: 
      `Payment in stages is important to us. We don't ask our clients to 
      make any advanced payments, so getting paid after each stage of work is our only 
      precaution. We hope for your understanding.`,
  },
  {
    title: `What is the price for a project under an extended license?`,
    description: 
      `The cost of work is $23/hour. You will receive an extended commercial 
      license, but all copyrights will remain with the studio, which means that we will 
      use objects from digital works at our discretion. For example, later we will load 
      the work into the warehouse platforms.`,
  },
  {
    title: `How much does it cost to work under an exclusive license?`,
    description: formatLink(),
  },
  {
    title: `How can I edit source files?`,
    description: 
      `We provide source files in formats such as .ai, .eps, .jpeg. If 
      you want to change something in an illustration, it's best to use Adobe Illustrator. 
      Other programs can help, but the full compatibility of .ai and .eps formats will only 
      be with AI. By the way, all our illustrations are created there.`,
  },
  {
    title: `Can you provide me with a PNG file with a transparent background?`,
    description:
      `Sure, we can provide you the source files in this format. However, if you need 
      such a file, please let us know in advance. All vector files intended for use in 
      PNG format must be created in a certain way from the very beginning in order for 
      all layers and effects to work correctly.`,
  },
  {
    title: `Do you prepare illustrations for printing?`,
    description: 
      `If we are working with you under our exclusive license, we can work 
      with the CMYK, best for the web. Otherwise, we'll use RGB colors 
      since we're uploading illustrations to stock platforms. This color system works 
      best for web. In any case, whatever the initial settings, we can help you prepare 
      the files for printing. To do this, we will need the exact requirements from your 
      printing shop, as these can vary from place to place.`,
  },
  {
    title: `How will I pay?`,
    description:
      `No advance payment is required. We offer a step-by-step payment system. This 
      means you will pay after every step (when we show any part of the work) or stage 
      (sketching, illustration) of work. This will depend on the illustration you need 
      to create.`,
  },
  {
    title: `What do we need for an invoice?`,
    description: formatText(),
  },
  {
    title: `What payment methods do you provide?`,
    description:
      `You can pay by card on our platform. If for some reason it is impossible for 
      you to pay by card, we can send you an invoice. In case there are any problems 
      with an invoice, we can provide you with our bank account details so you can 
      make a bank transfer.`,
  },
  {
    title: `What if I cancel the project?`,
    description: 
      `Project cancellation negatively affects our team. Please be sure 
      you want to do it. If the project was completed by BriefStock in part or in full 
      and you haven't paid for it, you can't place one more.`,
  },
];

export default FaqInfoComponent;
