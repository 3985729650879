import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './table-products.scss';

import { LoadingAdmin } from '@shared/ui';
import { Text, CheckBox, Button } from '@ui-kit';
import { PaginationInput, FiltersChecked } from '@widgets';
import { 
  getReadyProductsTC,
  getPaginationAmount,
  getIsLoading,
  setSearchPageAC,
  updateProductsSettingsTC,
  getAllAmount,
  getProductsAmountTC,
  SET_ENTIRE_AMOUNT,
} from '@entities';
import { ADMIN_PRODUCTS_URL } from '@app/routes';
import ProductsItem from './productsItem';
//import ItemActions from './itemActions';
import { STATUS_ACTIONS_LIST } from './item-actions.consts';
import RefreshIcon from '@ui-kit/icons/rotate-cw-gray400.svg';
import EyeOnIcon from '@ui-kit/icons/eye-gray400.svg';
import EyeOffIcon from '@ui-kit/icons/eye-off-gray400.svg';
import TrashIcon from '@ui-kit/icons/trash-gray400.svg';
import SortIcon from '@ui-kit/icons/code-gray500.svg';

const TableProducts = ({ products = [], setModalDeleteProduct, setModalHideProduct }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const productsPaginationAmount = useSelector(getPaginationAmount);
  const productsIsLoading = useSelector(getIsLoading)
  const allProductsAmount = useSelector(getAllAmount);

  const [page, setPage] = useState();
  const [limit, setLimit] = useState();
  const [field, setField] = useState();
  const [order, setOrder] = useState();
  const [allPage, setAllPage] = useState(1);
  const [isAllProductsChecked, setAllProductsChecked] = useState(false);
  const [forceRender, setForceRender] = useState(true);
  const [checkedProducts, setCheckedProducts] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const isEmpty = !+products.length;

  useEffect(() => {
    dispatch(setSearchPageAC('products'));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getProductsAmountTC(null, SET_ENTIRE_AMOUNT));
  }, [dispatch]);

  useEffect(() => {
    if(productsIsLoading) {
      return setForceRender(true);
    }

    setTimeout(() => setForceRender(false), 1000);
  }, [productsIsLoading]);

  useEffect(() => {
    if (!checkedProducts.length) {
      setAllProductsChecked(false);
    };
  }, [checkedProducts]);
  
  useEffect(() => {
    setAllPage(productsPaginationAmount);
  }, [productsPaginationAmount]);

  useEffect(() => {
    const params = getSearchParams();
    const searchByArray = [];

    params.forEach(({ key, value }) => {
      switch (key) {
        case 'page': {
          if (!+value || +value < 1) {
            searchParams.set('page', 1);
            setSearchParams(searchParams);
            return setPage(1);
          }

          setPage(value);
          break;
        }

        case 'limit': {
          if (!+value || +value < 1) {
            searchParams.set('limit', 80);
            setSearchParams(searchParams);
            setAllPage(Math.ceil(+productsPaginationAmount));

            return setLimit(80);
          }

          const allPages = Math.ceil(+productsPaginationAmount / +value);
          
          setAllPage(allPages);
          //setPurchasesLimitAC(+value);
          setLimit(+value);

          if (allPages < page) {
            setPage(allPages);
            searchParams.set('page', allPages);
            setSearchParams(searchParams);
          }

          break;
        }

        case 'fieldType': {
           // views, likes, createdAt, earned
          if (!value || value === '') {
            searchParams.set('fieldType', 'wentPublicAt');
            setSearchParams(searchParams);
            setField('wentPublicAt');
            return;
          }

          setField(value);
          //setPurchasedFieldAC(value);
          break;
        }

        case 'orderType': {
           // DESC, ASC
          if (!value || value === '') {
            searchParams.set('orderType', 'DESC');
            setSearchParams(searchParams);
            setOrder('DESC');
            return;
          }

          setOrder(value);
          //setPurchasedOrderAC(value);
          break;
        }

        case 'searchBy': {
          searchByArray.push(value);
          break;
        }

        default:
          break;
      }
    });

    if (searchByArray.length) {
      //const actualSearchValue = searchByArray.join(',');
      //dispatch(setSearchAC(actualSearchValue));
      //dispatch(setResultSearchAC(actualSearchValue));
    }

    return () => {
      //dispatch(setSearchAC(''));
      //dispatch(setResultSearchAC(''));
    };

    // eslint-disable-next-line
  }, [searchParams, productsPaginationAmount]);

  const getMyProducts = () => {
    const search = window.location.search;
    const result = search.slice(search.indexOf('?') + 1).split('&').reduce((params, hash) => {
      let [key, val] = hash.split('=')
      return Object.assign(params, {[key]: decodeURIComponent(val)})
    }, {})
    const options = {
      ...result,
      limit: result.limit || 80,
      page: result.page || 1,
      /*fieldType: result?.fieldType || 'createdAt',
      orderType: result?.orderType || 'DESC',*/
    };

    dispatch(getReadyProductsTC(options));
  };
  
  const getSearchParams = () => {
    const params = [];

    searchParams.forEach((value, key) => {
      params.push({ key, value });
    });

    return params;
  };

  const onChangeValue = (e) => {
    const value = e.target.value;
    setPage(value);
  };

  const onPageChange = (event) => {
    event.preventDefault();

    const page = +event.target.page.value;

    if (!page) {
      return;
    }

    if (page > allPage) {
      setPage(allPage);
      searchParams.set('page', allPage);
      setSearchParams(searchParams);
      return;
    }

    setPage(page);
    searchParams.set('page', page);
    setSearchParams(searchParams);
    getMyProducts();
  };

  const onClickPrev = () => {
    const params = getSearchParams();
    let numberPage = 0;

    params.forEach((param) => {
      if (param.key === 'page') {
        numberPage = param.value;
      }
    });

    if (!numberPage || numberPage <= 1) {
      return;
    }

    const page = +numberPage - 1;
    searchParams.set('page', page);
    setSearchParams(searchParams);
    setPage(page);
    getMyProducts();
  };

  const onClickNext = () => {
    const params = getSearchParams();
    let numberPage = 0;

    params.forEach((param) => {
      if (param.key === 'page') {
        numberPage = +param.value;
      }
    });

    if (!numberPage || numberPage >= allPage) {
      return;
    }

    const page = +numberPage + 1;
    searchParams.set('page', page);

    setSearchParams(searchParams);
    setPage(page);
    getMyProducts();
  };

  const onUpdateData = () => {
    const limit = searchParams.get('limit') || 80;
    const page = searchParams.get('page') || 0;
    const isPublic = searchParams.get('isPublic') || 1;
    searchParams.delete('isPromoted');
    setSearchParams(searchParams);

    setCheckedProducts([]);
    
    return setTimeout(() => 
      dispatch(getReadyProductsTC({ limit: limit, page: page, isPublic: isPublic }))
  , 500);
  };

  const onChangeSort = (currentField) => {
    if(currentField !== field) {
      searchParams.set('fieldType', currentField);
      searchParams.set('orderType', 'DESC');
      setSearchParams(searchParams);

      setField(currentField);
      setOrder('DESC');
      return getMyProducts();
    }

    const params = getSearchParams();
    let currentOrder = order;

    params.forEach((param) => {
      if (param.key === 'orderType') {
        currentOrder = param.value;
      }
    });

    let newOrder = ''
    if (currentOrder === 'DESC') {
      newOrder = 'ASC';
    } else if (currentOrder === 'ASC') {
      newOrder = 'DESC';
    } else {
      newOrder = 'DESC';
    };

    searchParams.set('orderType', newOrder);
    setSearchParams(searchParams);

    setOrder(newOrder);
    getMyProducts();
  };

  const onChangeStatus = () => {
    getMyProducts();
    /*switch (action) {
      case 'reset': {
        return onUpdateData();
      }
      case 'commercial': {
        searchParams.set('isPromoted', 0);
        setSearchParams(searchParams);

        setCheckedProducts([]);
        return dispatch(getReadyProductsTC({ limit: 80, page: 1, isPublic: 1, isPromoted: 0 }))
      }
      case 'promoted': {
        searchParams.set('isPromoted', 1);
        setSearchParams(searchParams);

        setCheckedProducts([]);
        return dispatch(getReadyProductsTC({ limit: 80, page: 1, isPublic: 1, isPromoted: 1 }))
      }
      default: break
    }*/
  }

  const onAllProductsChecked = (event) => {
    const value = event.target.checked;
    setAllProductsChecked(value)

    if(value) {
      setCheckedProducts([...products])
    } else {
      setCheckedProducts([])
    }
  };

  const onPromotedProduct = (id) => {
    dispatch(updateProductsSettingsTC({ ids: [id], update: {isPromoted: 1}}))
    onUpdateData();
  }

  const onEditProduct = (id) => {
    navigate(`${ADMIN_PRODUCTS_URL.pathname}/${id}`)
  }

  const onHideProducts = (listProd) => {
    const prodIds = [];
    listProd.map((item) => prodIds.push(item.id));

    setModalHideProduct({status: true, productsId: prodIds})
  };

  const onActiveProducts = (listProd) => {
    const prodIds = [];
    listProd.map((item) => prodIds.push(item.id));

    dispatch(updateProductsSettingsTC({ ids: prodIds, update: {isPublic: 1}}))
    onUpdateData();
  };

  const onDeleteProducts = (listProd) => {
    const prodIds = [];
    listProd.map((item) => prodIds.push(item.id));

    setModalDeleteProduct({status: true, productsId: prodIds})
  };

  const onSelectAction = (action, id) => {
    switch (action) {
      case 'promote': {
        return onPromotedProduct(id)
      }
      case 'edit': {
        return onEditProduct(id)
      }
      case 'hide': {
        return onHideProducts([{id: id}])
      }
      case 'activate': {
        return onActiveProducts([{id: id}])
      }
      case 'delete': {
        return onDeleteProducts([{id: id}])
      }
      default: break
    }
  };

  const renderTotal = () => {
    const isPublic = searchParams.get('isPublic');

    if(isPublic && isPublic === '1') {
      return (
        <Text as="h6" className="total__wrapper">
          Total {allProductsAmount} products
        </Text>
      );
    }

    const count = products.length ? productsPaginationAmount : 0;

    return (
      <Text as="h6" className="total__wrapper result">
        {count} products hidden
      </Text>
    );
    
  };

  const renderEyeButton = () => {
    const isPublic = searchParams.get('isPublic');
    if(isPublic && isPublic === '1') {
      return (
        <Button 
          theme="link-gray"
          size="lg"
          iconRight={EyeOffIcon}
          className="hide-products"
          onClick={() => onHideProducts(checkedProducts)}
        />);
    }

    return (
      <Button 
        theme="link-gray"
        size="lg"
        iconRight={EyeOnIcon}
        className="hide-products"
        onClick={() => onActiveProducts(checkedProducts)}
      />
    )
  }

  return (
      <div className="table-products__container">
        <div className="admin__table">
          <div className="admin__table-title">
            <div className="table-title__actions d-flex">
              <CheckBox
                className="checkbox__item"
                checked={isAllProductsChecked}
                onChange={onAllProductsChecked}
              />
              <Button 
                theme="link-gray"
                size="lg"
                iconRight={RefreshIcon}
                className="refresh-products"
                onClick={onUpdateData}
              />
              {!!checkedProducts.length && 
                <>
                  {renderEyeButton()}
                  <Button 
                    theme="link-gray"
                    size="lg"
                    iconRight={TrashIcon}
                    className="delete-products"
                    onClick={() => onDeleteProducts(checkedProducts)}
                  />
              </>}
            </div>
            <div>
              {renderTotal()}
            </div>
          </div>

          <div className="admin__table-header">
              <Text as="h6" className="product-name">
                Product name
              </Text>
              <Button 
                onClick={() => onChangeSort('wentPublicAt')} 
                size="md" 
                theme="link-gray" 
                iconRight={SortIcon} 
                className="product-date"
              >
                Release date
              </Button>
              <Button 
                onClick={() => onChangeSort('amount_views')} 
                size="md" 
                theme="link-gray" 
                iconRight={SortIcon} 
                className="product-views"
              >
                Views
              </Button>
              <Button 
                onClick={() => onChangeSort('amount_likes')} 
                size="md" 
                theme="link-gray" 
                iconRight={SortIcon} 
                className="product-favorites"
              >
                Favorites
              </Button>

              <FiltersChecked
                className="product-status"
                filters={STATUS_ACTIONS_LIST}
                onChangeFilter={onChangeStatus}
              >
                <Text as="h6">Status</Text>
              </FiltersChecked>
              
              <Button 
                onClick={() => onChangeSort('earned')} 
                size="md" 
                theme="link-gray" 
                iconRight={SortIcon} 
                className="product-earned"
              >
                Earned
              </Button>
              <Text as="h6" className="product-item__menu">
                {''}
              </Text>
          </div>
          
            <div className="admin__table-body">
              {forceRender ? <LoadingAdmin /> : 
                products.map((item, index) => (
                  <ProductsItem
                    key={`product-${index}`}
                    item={item}
                    checkedItems={checkedProducts}
                    setCheckedItems={setCheckedProducts}
                    onChange={onSelectAction}
                  />
                ))
              }
            </div>
          
        </div>
        <PaginationInput 
          allPage={allPage}
          onSubmit={onPageChange}
          onClickPrev={onClickPrev}
          onClickNext={onClickNext}
          currentPage={page}
          onChangeValue={onChangeValue}
          isDisabled={isEmpty}
          currentLimit={limit}
          viewList={false}
        />
    </div>
  );
};

export default TableProducts;
