import Goal from '@ui-kit/icons/target-accent600.svg';
import HeartOutline from '@ui-kit/icons/heart-accent600.svg';
import GeoTag from '@ui-kit/icons/map-pin-accent600.svg';
import Dollar from '@ui-kit/icons/dollar-accent600.svg';
import Copy from '@ui-kit/icons/copy-accent600.svg';

export const RIGHT_PRODUCTS = [
  {
    title: 'Goals',
    description: 'Take advantage of our digital products for your business or personal goals',
    ico: Goal,
    alt: 'goals',
  },
  {
    title: 'Budget',
    description:
      'No restrictions. Our illustrations can be implemented in projects of any size',
    ico: Dollar,
    alt: 'budget',
  },
  {
    title: 'End product',
    description:
      'Create all sorts of items and designs with our illustrations',
    ico: HeartOutline,
    alt: 'end product',
  },
  {
    title: 'Multiple use',
    description:
      'No limit on the number of usages for one project',
    ico: Copy,
    alt: 'multiple use',
  },
  {
    title: 'Worldwide',
    description:
      'Use our illustrations anywhere in the world',
    ico: GeoTag,
    alt: 'worldwide',
  },
];
