import React, { useEffect, useState } from 'react';
import './productsActions.scss';

import { Dropdown, Text } from '@ui-kit';
import { useSearchParams } from 'react-router-dom';

const PRODUCT_DROPDOWN_LIST = [
  { name: 'Popular', value: 'popular' },
  { name: 'Newest', value: 'createdAt' },
];

const ProductsActions = ({ onChangeSorting = ()=>{} }) => {
  const [sortBy, setSortBy] = useState('');
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const params = searchParams.get('orderBy');
    
    if (params) {
      setSortBy(params);
    };
  }, [searchParams]);

  const renderDropDowns = (item) => {
    const classes = ['input-search__item'];

    if (item.value === sortBy) {
      classes.push('active');
    } 

    return (
      <Text 
        as="h6" 
        key={item.value} 
        className={classes.join(' ')} 
        onClick={()=> onSetSort(item.value)}
      >
        {item.name}
      </Text>
    )
  }

  const onSetSort = (value) => {
    setSortBy(value);

    onChangeSorting(value);
  }

  return (
    <div className="products__container-actions d-flex ai-center">
      <Text as="h5" className="action__text mr-16">
        Sort by:{' '}
      </Text>
      <Dropdown
        id="products-dropdown"
        data={PRODUCT_DROPDOWN_LIST}
        showArrow={true}
        placeholder= "Sort by"
        value={sortBy}
        showAvatar={true}
        renderOption={renderDropDowns}
      />
    </div>
  );
};

export default ProductsActions;
